import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components';
import Category from './Category';
import AddCatagory from './AddCatagory';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { Accordion, Card, Button, Table, Row, Col } from 'react-bootstrap';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../../../aws-exports';
import EdiText from 'react-editext';
import { FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getLineItemByIDs, getLineItemByID, getDocStructure, createDocCatWithLineItems, createDocCategory, updateDocCatSortOrder } from '../../../graphql/queries';
import Move from '../../../assets/move_cat.svg'
import { FaGripVertical } from "react-icons/fa";
import { GoKebabVertical } from "react-icons/go";
// import LineItem from './LineItem';
import { insulation_category, roof_category, siding_category, gutter_category, painting_category, concrete_category } from "../category_constants";
Amplify.configure(config);


export default function Strucpostture(props) {
    const {consoleLog} = require('../../commonFunctions.js');
    consoleLog('WOStrucpostture props: ', props)

    const updatedStructure = useSelector((state) => state.templateStructure);
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [structureIndex, setStructureIndex] = React.useState(props.structureIndex);
    const [categories, setCategories] = React.useState(props.categoryData);

    const [showLoaderx, setShowLoaderx] = React.useState(false);

    const dispatch = useDispatch();

    useEffect( () => {
        consoleLog('struce all data again',props)
        consoleLog('struce all stData', stData)

        setStData(updatedStructure);

        setCategories(props.structures_data.categories ? props.structures_data.categories : [])
        setStructureIndex(props.structureIndex)
        consoleLog("Strucpostture on useEffect sturctureIndex: ", structureIndex)
    }, [props.structures_data.categories, structureIndex]);
    
    const updateStructureName = (updated_structure_name) =>{ // No need of this in work order
        let allData = {...stData};
        consoleLog("updateStructureName structureIndex: ", structureIndex)
        allData.structures[structureIndex].name = updated_structure_name;
        consoleLog("updateStructureName allData: ", allData)
        props.onChange(allData);
        if(typeof props.url_params.doc_type_id != 'undefined'){ //done
            props.updateDocOnElasticSearch();
        }
    }
    
    function removeStructure(e){ // No need of this in work order
        let allData = { ...stData };
        consoleLog("removeStructure structureIndex: ", structureIndex)
        allData.structures.splice([structureIndex],1);
        props.onChange(allData);
        if(typeof props.url_params.doc_type_id != 'undefined'){ //done
            props.updateDocOnElasticSearch();
        }
    }

    function handleUpdateCategory(structures_data){
        consoleLog("handleUpdateCategory structures_data: ",structures_data)
        dispatch({ type: "TEMPLATE_STRUCTURE", value: structures_data });
    }
    
    async function categoryAdd(category, category_structure_index){ // adding user category to temp / doc
        var categoryCopy = [...categories];
        category.sort_order = categoryCopy.length;
        categoryCopy.push(category)
        setCategories(categoryCopy);

        consoleLog("categoryAdd category_structure_index: ", category_structure_index)
        consoleLog("categoryAdd category: ",category);
        consoleLog("categoryAdd category.name: ",category.name);
        let allData = { ...stData };
        
        allData.structures[category_structure_index].categories = [...categoryCopy];
        setStData(allData);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        consoleLog("categoryAdd allData: ",allData);

        if(typeof props.url_params.doc_id != 'undefined'){
            let doc_structure_id = allData.structures[category_structure_index].doc_structure_id;
            let sort_order = allData.structures[category_structure_index].categories.length;
            sort_order = sort_order - 1;
            consoleLog("categoryAdd doc_structure_id: ",doc_structure_id);
      
            const create_cat = await API.graphql({
              query: createDocCategory,
              variables: {
                doc_id: props.url_params.doc_id,
                doc_structure_id: doc_structure_id,
                category_id: 0,
                name: category.name,
                notes: '',
                sort_order: sort_order
              },
            });
            let doc_cat_id = JSON.parse(create_cat.data.createDocCategory).insertId;
            consoleLog("categoryAdd doc_cat_id: ",doc_cat_id);

            allData.structures[category_structure_index].categories.find((c) => c.category_id == category.category_id).doc_id = parseInt(props.url_params.doc_id);
            allData.structures[category_structure_index].categories.find((c) => c.category_id == category.category_id).doc_category_id = doc_cat_id;
            allData.structures[category_structure_index].categories.find((c) => c.category_id == category.category_id).doc_structure_id = doc_structure_id;
            setStData(allData);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            consoleLog("categoryAdd allDatax: ",allData);
            props.updateDocOnElasticSearch();
        }
    }

    // adding new category
    async function categorySave(category, category_structure_index){
        let allData = {...stData};

        consoleLog("categorySave category: ", category);
        var categoryCopy = [...categories];
        category.sort_order = categoryCopy.length;
        consoleLog("categorySave categoryCopy: ", categoryCopy);
        categoryCopy.push(category)
        setCategories(categoryCopy);

        consoleLog("categorySave categoryCopyx: ", categoryCopy);

        consoleLog("categorySave category_structure_indexx: ", category_structure_index)
        
        consoleLog('categorySave allDataa', allData);
        allData.structures[category_structure_index].categories= categoryCopy;
        // dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        // setStData(allData);

        if(category.line_items < 1 || typeof category.line_items == 'undefined'){
            consoleLog('categorySave XXXXX');
            getLineItems(category, category_structure_index);
        }else{
            consoleLog('categorySave x allData size', allData.structures[category_structure_index].categories.length);
            if(typeof props.url_params.doc_id != 'undefined'){
                consoleLog('categorySave props.url_params.doc_id', props.url_params.doc_id);
                let doc_id = props.url_params.doc_id;
                let doc_struct_id = allData.structures[category_structure_index].doc_structure_id;
                let sort_order = allData.structures[category_structure_index].categories.length;
                sort_order = sort_order - 1;
                let categoryIndex = allData.structures[category_structure_index].categories.length - 1;

                const create_category = await API.graphql({
                query: createDocCategory,
                variables: {
                    doc_id: doc_id,
                    doc_structure_id: doc_struct_id,
                    category_id: category.category_id,
                    name: category.name,
                    notes: '',
                    sort_order: sort_order
                },
                });
                consoleLog('categorySave x create_category', create_category);
                let doc_category_id = JSON.parse(create_category.data.createDocCategory).insertId;
                allData.structures[category_structure_index].categories[categoryIndex].doc_id = parseInt(doc_id);  
                allData.structures[category_structure_index].categories[categoryIndex].doc_category_id = doc_category_id;  
                allData.structures[category_structure_index].categories[categoryIndex].doc_structure_id = doc_struct_id;
                
                consoleLog('categorySave x allData after', allData);
                dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
                setStData(allData);
                props.updateDocOnElasticSearch(); //done
            }
        }

        consoleLog('categorySave allData', allData);
        consoleLog('categorySave allDatay', JSON.stringify(allData));
        consoleLog('categorySave allDatax', allData.structures[category_structure_index].categories);
    }
    
    async function getLineItems(category_data, category_structure_index) { // when we add a category lineItems are added from here
        setShowLoaderx(true);
        consoleLog('getLineItems category_structure_index',category_structure_index);
        consoleLog('getLineItems category_data.default_data', category_data.default_data);
        var allData = { ...stData };
        var categoryIndex = allData.structures[category_structure_index].categories.length - 1;
        if(category_data.default_data != null){
            let lineItemsDefaultIds = JSON.parse(category_data.default_data);
            consoleLog('getLineItems lineItemsDefaultIds wo',lineItemsDefaultIds[2].line_items); // for work order

            let new_line_ids = lineItemsDefaultIds[2].line_items;
            new_line_ids = new_line_ids.join();
            consoleLog('getLineItems new_line_ids',new_line_ids);
      
            const get_line_items_data = await API.graphql({
              query: getLineItemByIDs,
              variables: {
                ids: new_line_ids,
              },
            });
            
            let new_line_items = JSON.parse(get_line_items_data.data.getLineItemByIDs);
            new_line_items = JSON.parse(new_line_items).data;
            consoleLog('getLineItems new_line_items>>>',new_line_items);
      
            allData.structures[category_structure_index].categories[categoryIndex].line_items = new_line_items;
            
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            setStData(allData);
        }

        // if(lineItemsDefaultIds != null){ //get each line item by id //old approach
        //     let lineItems = lineItemsDefaultIds[2].line_items;
        //     for(let lineId of lineItems){
        //         const get_line_item_data = await API.graphql({
        //             query: getLineItemByID,
        //             variables:{
        //                 line_item_id: lineId
        //             }
        //         });
        //         consoleLog("get_line_item_data: ", get_line_item_data)
        //         if(get_line_item_data){
        //             let get_line_item_reocrd = get_line_item_data.data.getLineItemByID;

        //             let adj_quantity = (1 * get_line_item_reocrd.adjusted_quantity);
        //             let cost = (1 * get_line_item_reocrd.cost);
        //             let netCost = adj_quantity * cost;

        //             netCost =  Number.parseFloat(netCost).toFixed(2);

        //             let temp_object = {};
        //             temp_object.net_cost = netCost;
        //             temp_object.hide_unit_price_column = 0;

        //             let li_object = Object.assign(get_line_item_reocrd, temp_object);
                
        //             if(get_line_item_reocrd.sub_line_item == "[]"){
        //                 get_line_item_reocrd.sub_line_item = [];
        //             }
        //             else{
        //                 get_line_item_reocrd.sub_line_item = JSON.parse(get_line_item_reocrd.sub_line_item);
        //             }
        //             var allData = {...stData};              
        //             consoleLog("get_line_item_reocrd: ", get_line_item_reocrd)
                    
        //             var categoryIndex = allData.structures[category_structure_index].categories.length - 1 ;                
        //             category_line_items = allData.structures[category_structure_index].categories[categoryIndex].line_items;
        //             category_line_items = [...category_line_items, get_line_item_reocrd];

        //             allData.structures[category_structure_index].categories[categoryIndex].line_items = category_line_items;

        //             dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        //             consoleLog('getLineItems 158>',category_line_items);
        //             setStData(allData);
        //         }
        //     }
        // }
        // var categoryIndex = allData.structures[category_structure_index].categories.length - 1; // to get line items by constants //old approach
        // if(category_data.category_id == 10){
        //     allData.structures[category_structure_index].categories[categoryIndex].line_items = insulation_category.line_itemx;
        //     category_line_items = insulation_category.line_itemx;
        // }
        // if(category_data.category_id == 8){
        //     allData.structures[category_structure_index].categories[categoryIndex].line_items = roof_category.line_itemx;
        //     category_line_items = roof_category.line_itemx;
        // }
        // if(category_data.category_id == 14){
        //     allData.structures[category_structure_index].categories[categoryIndex].line_items = siding_category.line_itemx;
        //     category_line_items = siding_category.line_itemx;
        // }
        // if(category_data.category_id == 9){
        //     allData.structures[category_structure_index].categories[categoryIndex].line_items = gutter_category.line_itemx;
        //     category_line_items = gutter_category.line_itemx;
        // }
        // if(category_data.category_id == 31){
        //     allData.structures[category_structure_index].categories[categoryIndex].line_items = painting_category.line_itemx;
        //     category_line_items = painting_category.line_itemx;
        // }
        // if(category_data.category_id == 16){
        //     allData.structures[category_structure_index].categories[categoryIndex].line_items = concrete_category.line_itemx;
        //     category_line_items = concrete_category.line_itemx;
        // }
        let doc_id = props.url_params.doc_id; //1
        if(typeof props.url_params.doc_type_id != 'undefined'){
            // consoleLog('categorySave doc_type:', props.url_params.doc_type_id); // create the category with line items //old approach
            // consoleLog('categorySave doc_id>', doc_id);
            // var category_line_itemsx = JSON.stringify(category_line_items);
            // consoleLog('categorySave lineObject>',category_line_itemsx);
            // let category_datax = JSON.stringify(category_data);
            // consoleLog('categorySave strObject>',category_datax);

            // var doc_struct_id = allData.structures[category_structure_index].doc_structure_id; //2
            // consoleLog('categorySave doc_struct_id>',doc_struct_id);

            // const save_data_in_db = await API.graphql({
            //     query: createDocCatWithLineItems,
            //     variables:{
            //         doc_id:doc_id,
            //         doc_structure_id: doc_struct_id,
            //         strObject: category_datax,
            //         lineObject: category_line_itemsx
            //     }
            // });
            // let cat_line_data = JSON.parse(save_data_in_db.data.createDocCatWithLineItems);
            // consoleLog('categorySave save_data_in_db', cat_line_data.data); ///xxx
            // allData.structures[category_structure_index].categories[categoryIndex] = cat_line_data.data;
            // dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            // consoleLog('categorySave getLineItems 158>',category_line_items);
            // consoleLog('categorySave allDataxxx', JSON.stringify(allData));
            // setStData(allData);
            consoleLog('getLineItems x allData size', allData.structures[category_structure_index].categories.length);
            let doc_struct_id = allData.structures[category_structure_index].doc_structure_id;
            let sort_order = allData.structures[category_structure_index].categories.length;
            sort_order = sort_order - 1;
            consoleLog('getLineItems x doc_id', doc_id);
            consoleLog('getLineItems x doc_struct_id', doc_struct_id);
            consoleLog('getLineItems x category_data', category_data);
            consoleLog('getLineItems x category_structure_index', category_structure_index);
            const create_category = await API.graphql({
            query: createDocCategory,
            variables: {
                doc_id: doc_id,
                doc_structure_id: doc_struct_id,
                category_id: category_data.category_id,
                name: category_data.name,
                notes: '',
                sort_order: sort_order
            },
            });
            let doc_category_id = JSON.parse(create_category.data.createDocCategory).insertId;
            consoleLog('getLineItems x doc_category_id', doc_category_id);
            consoleLog('getLineItems x allData size', allData.structures[category_structure_index].categories.length);
            consoleLog('getLineItems x allData before', allData.structures[category_structure_index].categories[categoryIndex]);
            allData.structures[category_structure_index].categories[categoryIndex].doc_id = parseInt(doc_id);
            allData.structures[category_structure_index].categories[categoryIndex].doc_category_id = doc_category_id;
            allData.structures[category_structure_index].categories[categoryIndex].doc_structure_id = doc_struct_id;
            consoleLog('getLineItems x allData after', allData.structures[category_structure_index].categories[categoryIndex]);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            setStData(allData);
            props.updateDocOnElasticSearch(); //done
        }
        setShowLoaderx(false);
    }

    function handleOnDragEnd(results){
        consoleLog("handleOnDragEnd results: ", results)
        if(!results.destination) return;

        const cate = Array.from(categories);
        const [reorderedCat] = cate.splice(results.source.index, 1);
        cate.splice(results.destination.index, 0, reorderedCat);

        if(typeof props.url_params.doc_id != 'undefined'){
            cate.forEach((cat, i)=>{
                cat.sort_order = i;
                API.graphql({
                    query: updateDocCatSortOrder,
                    variables: {
                        doc_category_id: cat.doc_category_id,
                        sort_order: i
                    }
                });
            });
        }

        consoleLog("handleOnDragEnd cate: ", cate)
        consoleLog("handleOnDragEnd structureIndex: ", structureIndex)
        props.changedCategoryPosition(cate, structureIndex);

        if(typeof props.url_params.doc_id != 'undefined'){
            props.updateDocOnElasticSearch();
        }
    }

    function reorderCat(newData){
        setCategories(newData);
    }

	return (
        
        <Struct>          
            <Accordion className="w-100 mb-3" style={{ borderRadius: "6px 6px 0 0" }}>
                <Card className="bg-trans no-shade" style={{overflow: 'unset'}}>
                    <div>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="droppable-1">
                                {(provided, _)=>(
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {categories.map((category, i) => (
                                            <Draggable key={i} draggableId={"draggable-"+i} index={i}>
                                                {(provided, snapshot)=>(
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            boxShadow: snapshot.isDragging
                                                                ? "0 0 .7rem #000"
                                                                : "none",
                                                        }}
                                                    >
                                                        <Handle {...provided.dragHandleProps}>
                                                            <img src={Move} alt="" style={{paddingLeft:'10px'}}/>
                                                        </Handle>
                                                        <Category 
                                                            onReorder={reorderCat}
                                                            defaultManufacturers={props.defaultManufacturers}
                                                            userManufacturers={props.userManufacturers}
                                                            setUserManufacturers={props.setUserManufacturers}
                                                            structureIndex={props.structureIndex}
                                                            category={category} onChange={handleUpdateCategory}
                                                            key={i+"_category"}
                                                            url_params={props.url_params}
                                                            showLoaderx={showLoaderx}
                                                            setShowLoaderx={setShowLoaderx}
                                                            show={props.show}
                                                            showMessage={props.showMessage}
                                                            showMessageType={props.showMessageType}
                                                            setShow={props.setShow}
                                                            setShowMessage={props.setShowMessage}
                                                            setShowMessageType={props.setShowMessageType}
                                                            updateDocOnElasticSearch={props.updateDocOnElasticSearch}
                                                            calculateSubTotal={props.calculateSubTotal}
                                                        /> 
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        <AddCatagory 
                                            onAdd={categorySave}
                                            onChange={categoryAdd}
                                            structureIndex={props.structureIndex}
                                            categories={categories}
                                            url_params={props.url_params}
                                            show={props.show}
                                            showMessage={props.showMessage}
                                            showMessageType={props.showMessageType}
                                            setShow={props.setShow}
                                            setShowMessage={props.setShowMessage}
                                            setShowMessageType={props.setShowMessageType}
                        />
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Card>
            </Accordion>
        </Struct>
	)
}

const Handle = styled.div`
  position: absolute;
  left: 0px;
  z-index: 99;
  margin: 20px 10px;
  cursor: grab;
`;


const Struct = styled.div`
    width: 100%;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: space-around;
    // padding: 10px;
    
    .struc_header{
        padding: 0px 12px; 
    }

    .fDlEee{
        margin: 0.5em;
    }
    .my-custom-view-wrapper{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding-left: 15px !important;
    }
    
    .styles-module_Editext__button__sxYQX{
        background-color:transparent !important;
        content:'Edit' !important;
        border:none !important;
        outline:none !important;
    }

    .styles-module_Editext__input__2-M50{
        color:black !important;
    }
   
    .styles-module_Editext__edit_button__310_J{
        color:#c2c2c2 !important;
    }
    .styles-module_Editext__save_button__1Dlwo {
        color:#c2c2c2 !important;
    }
   
`
