import React, { Component, useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Accordion, Card, Button, Table, Row, Col } from 'react-bootstrap';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../../aws-exports';
import Cancel from '../../assets/cancel_line.svg'
import Plus from '../../assets/plus.svg'
import { FaPlus} from "react-icons/fa";
import ToastBox from '../Toast/Toast';
import CLight from "../../assets/add_gray.svg";
import { createStructure } from "../../graphql/queries";
import { isEmpty } from "../CommonComponents/helper";
Amplify.configure(config);

export default function AddStructure(props) {
    const {consoleLog} = require('../commonFunctions.js');
    const [addStructureTitle, setAddStructureTitle] = useState(true);
    const [structure_name, setStructureText] = React.useState('');
    const [show, setShow] = useState(false);

    function showHideStructureForm(){
        addStructureTitle ? setAddStructureTitle(false) : setAddStructureTitle(true);
    }

    async function saveTemplateStructure(){ // to add structure
        consoleLog('saveTemplateStructure structure_name',structure_name);
        consoleLog('saveTemplateStructure props',props);
        if(structure_name != ""){
            setAddStructureTitle(!addStructureTitle);
            const structNew = {...props.structData};
            const struct = {};
            struct.name = structure_name;
            struct.categories=[];
            let count = structNew.structures.length;
            struct.structure_id = count;
            struct.sort_order = count;
            if(typeof props.url_params.doc_type_id != 'undefined'){
                consoleLog('>>>',count);
                const create_structure = await API.graphql({
                    query: createStructure,
                    variables: {
                        doc_id: props.url_params.doc_id,
                        name: structure_name,
                        sort_order: count,
                        structure_id: count
                    },
                });
                consoleLog('saveTemplateStructure structure', create_structure);
                let create_structure_result = create_structure.data.createStructure;
                let new_structure_id = JSON.parse(create_structure_result).insertId;
                consoleLog('saveTemplateStructure structure', new_structure_id);
                struct.doc_id=parseInt(props.url_params.doc_id);
                struct.doc_structure_id=new_structure_id;

                let quantities = structNew.quantities;
        
                if(typeof quantities === 'string'){
                    quantities = JSON.parse(structNew.quantities);
                }
                if(!isEmpty(quantities) && quantities[count] && quantities[count].structure_id === 0) {
                    quantities[count].structure_id = new_structure_id;
                    structNew.quantities = JSON.stringify(quantities);
                }
            }
            
            let structure_count = await props.updateStrCount();
            structNew.structure_count = structure_count;
            structNew.structures.push(struct);
            consoleLog('saveTemplateStructure structure',structNew);
            props.onChange(structNew);
            setStructureText('');
            props.setShow(true);
            props.setShowMessage('Structure has been added!');
            props.setShowMessageType('Success');
            if(typeof props.url_params.doc_type_id != 'undefined'){ //done
                props.updateDocOnElasticSearch();
            }
        }else{
            props.setShow(true);
            props.setShowMessage('Please enter Structure name.');
            props.setShowMessageType('Error');
        }
    }

    function handleStructureText(e){
        setStructureText(e.target.value);
    }

    

    return (
        <AddStruct>
            <div className="row buttons">
                <a className={`add-structure show-item-adder text-left ${!addStructureTitle ? "borders" : ""}`} onClick={showHideStructureForm}>
                    <p className="text m-0 "> <span className="h-auto mr-3"> <img src={CLight} style={{width:'25px', height:'auto'}} alt=""/></span> ADD A STRUCTURE</p>
                </a>

                <Col xs={12} className={`section structure-adder item-adder col text-left ${addStructureTitle ? "hide" : ""}`}>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <h2>Add a Structure</h2>
                        <a onClick={showHideStructureForm}><img src={Cancel} style={{height:'auto', width:'26px'}} alt=""/></a>
                    </div>
                   
                    <div  className="create-form form" >
                        <input type="hidden" name="type" value="markup"/>
                        <div className="input">
                            <Col className="col">
                                <label className="label-x">Enter New Structure Name</label>
                            </Col>
                            <Col className="col add-button">
                                <input value={ structure_name } onChange={handleStructureText} type="text" name="name" className="custom-input-x" placeholder="New Structure Name" defaultValue="" data-item-adder-focus="true" />
                                <button className="search-submit small pl-4" onClick={()=>saveTemplateStructure()} >
                                    <img src={Plus} alt=""/>
                                </button>
                            </Col>
                        </div>
                    </div>

                    {
                        props.show === true ?
                        <ToastBox
                        setShow={props.setShow}
                        show={props.show}
                        Text={props.showMessage}
                        Type={props.showMessageType}
                        />
                        :null
                    }

                </Col>
            </div>
        </AddStruct>
    )
}

const AddStruct = styled.div`
    min-height: auto;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-around;
    flex-direction:column;
    padding-bottom: 4rem;

    @media(max-width: 767px) {
        padding-bottom: 0.5rem;
    }

    .close{
        right:20px !important;
        top:1rem !important;
    }

    .toast-header{
        padding:0 20px;
    }
    .toast-body{
        padding:0 20px;
    }
    .buttons {
        padding: 0;
    }
    .row {
        position: relative;
        display: block;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
        float: none;
    }
    .left {
        float: left;
    }
    .add-structure {
        
        border-radius:4px;
        background: #212E33;
        padding: .6em 1.8em .6em 1.2em;
    }
    .show-item-adder {
        display: block;
        cursor: pointer;
        
       
    }
    a {
        color: #414141;
        text-decoration: none;
        outline: 0;
    }
    .color-white {
        color: white;
    }
    .add-structure .text {
        color: white;
        font-size: 16px;
        font-family:Rajdhani;
        font-weight:500;
        display: flex;
        align-items: center;
        line-height: normal;
        
    }
    .buttons .show-item-adder .text {
        font-size: 16px;
    }
    .buttons .show-item-adder {
        display: block;
        cursor: pointer;
        padding: 10px 14px;
    }
    
    .item-adder.hide {
        min-height: 0;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: none !important;
        display: none;
    }
    .item-adder {
        overflow: hidden;
        -webkit-animation: height-show .5s forwards;
        animation: height-show .5s forwards;
        background: #f5f5f5;
        padding: 2em 3.42em 3.57em 3.43em !important;
        position: relative;
        border: 1 px solid #f5f5f5;
        border-radius: 0 0 10px 10px;


        @media(max-width:600px){
            padding:2em 1em !important;
        }
    }
    .item-adder .close {
        position: absolute;
        top: 1em;
        right: 2em;
        cursor: pointer;
        z-index: 5;
    }
    .item-adder h2 {
        font-size: 1.1em;
        font-weight: 600;
        margin: 0;
        color: #212E33;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
    .col {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        text-align: center;
        position: relative;
        min-height: 1px;
        line-height: 1em;
        width: 100%;
        float: none;
        margin-right: -.3em !important;
    }
    .col:last-child {
        margin-right: 0 !important;
    }

    .col .add-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }
    h2 {
        font: 1.5em "Open Sans", Helvetica, sans-serif;
    }
    div, span, form{
        border: 0;
        font-size: 100%;
        font: inherit;
        line-height: 1.4em;
        vertical-align: baseline;
        margin: 0;
        padding: 0;
    }
    .item-adder .create-form {
        margin-top: 2.35em;

        .label-x{
            display:flex;
            align-items:center;
            justify-content:flex-start;
            width:100%;
            font-size:14px;
            font-family:Roboto;
            color:#212E33;
            font-weight:normal;
            margin-bottom : 7px !important;
        }
    }
    .custom-input-x{
        height:40px;
        width:100%;
        border-radius:4px;
        padding:10px;
        font-size:14px;
        color:#212E33;
        font-style:normal;
        border:1px solid #B4BEBF;
        outline:none;
    }
   
    /* button, .button, .row .button {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        background: #ed1c24;
        border: 1px solid #08527d;
        border-radius: 3px;
        padding: .8em 1em;
    } */
    /* button, .button, .row .button {
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        background: #ed1c24;
        border: none;
        border-radius: 3px;
        padding: .6em .8em;small
        box-sizing: border-box;
    } */
    button.small, .button.small, .row .button.small {
       height:40px;
       width:40px;
       background: #D6E1E5;
       border-radius:3px;
       outline:none;
       display:flex;
       align-items:center;
       justify-content:center;
       border:1px solid #D6E1E5;
       margin-left:10px;
    }
    .item-adder .create-form button {
        padding: .7em 1em;
    }
`