import React,{useState, useEffect} from 'react'
import styled from 'styled-components';
import CreateTemplate from '../Components/CreateTemplate/CreateTemplate'
import { useSelector, useDispatch } from "react-redux";
import { setEsDownFlagTemp, setEsDownFlagDoc, getDefaultManufacturersRedis, getClientByID, getUsersManufacturers, getCategoriesWithLineitemsRedis, elasticSearchFetchDoc, elasticSearchCreateDocOrTemp, elasticSearchFetchTemp, getDefaultManufacturer, getDefaultCategories, getCategoryByUserID, getUnits, getTemplate, getDocumentByID, getDocumentWorkOrderData, getDocumentEstimateData, getDocOrderIds, addDocOrders} from '../graphql/queries';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../aws-exports';
import * as queryString from "query-string";
import Loader from './../assets/loading.gif';
import ToastBox from '../Components/Toast/Toast';
import { Helmet } from 'react-helmet';
import  secureLocalStorage  from  "react-secure-storage";

Amplify.configure(config);

export default function MainCreateTemplate(props) {
    const {consoleLog} = require('../Components/commonFunctions.js');
    consoleLog("MainCreateTemplate props: ", props);

    const url_params = queryString.parse(props.location.search);
    const prevState = props.location && props.location.state && props.location.state.error ? props.location.state.error : false;
    consoleLog("MainCreateTemplate url_params: ", url_params )

    const [templateInfo, setDocType] = React.useState(useSelector((state) => state.templateInfo));
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [default_category, setDCategory] = React.useState(useSelector((state) => state.defaultCategories));
    const [category_by_user_id, setDCategoryByUserId] = React.useState(useSelector((state) => state.getCategoryByUserID));
    const [defaultCategory, setUserDefaultCategory] = React.useState(useSelector((state) => state.defaultCategories));
    const [userCategory, setUserCategory] = React.useState(useSelector((state) => state.getCategoryByUserID));
    const [defaultManufacturers, setDefaultManufacturers] = React.useState(useSelector((state) => state.defaultManufacturer));
    const [userManufacturers, setUserManufacturers] = React.useState([]);
    const [d_manf, setDMan] = React.useState(useSelector((state) => state.defaultManufacturer));
    const [loading, setLoading] = useState(true);

    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [showMessageType, setShowMessageType] = useState('');

    const dispatch = useDispatch();

    useEffect(()=>{
        let doc_type_id = url_params.doc_type_id ? url_params.doc_type_id : null;
        let doc_id = url_params.doc_id ? url_params.doc_id : null;
        fetchUnits();
        fetchCategories();
        fetchDefaultManufacturer();
        fetchTemplateRecord(url_params.template_id, doc_type_id, doc_id);
    }, []);


    async function fetchTemplateRecord(template_id, doc_type_id, doc_id){
        consoleLog("fetch record of template: " + template_id + ", doc_type_id: " + doc_type_id+" doc_ic"+doc_id);
        let template_record = {};
        let template_info = templateInfo;
        let allData = stData;
        let doc_found = false;
        let temp_found = false;
        let es_down_docs = [];
        let es_down_temps = [];
        ////Check if this document / template was edited when ES down
        if(doc_id){
            consoleLog('es_down_docs>',secureLocalStorage.getItem("es_down_docs"));
            es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
            consoleLog('es_down_docs>>>', es_down_docs);
            for (let index = 0; index < es_down_docs.length; index++) {
                if(es_down_docs[index].doc_id == doc_id){
                    doc_found = true;
                    break;
                }
            }
            consoleLog('es_down_docs doc_found>>>', doc_found);
        }else{
            es_down_temps = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
            consoleLog('es_down_docs>>>', es_down_temps);
            for (let index = 0; index < es_down_temps.length; index++) {
                if(es_down_temps[index].template_id == template_id){
                    temp_found = true;
                    break;
                }
            }
            consoleLog('es_down_docs temp_found>>>', temp_found);
        }
        ////
        if(doc_type_id == 2){   //  will run in case of Document Work Order

            template_record = await API.graphql({
                query: elasticSearchFetchDoc,
                variables: {
                    doc_id: doc_id
                }
            });
            let template_status = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchDoc)).status;
            template_record = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchDoc)).data;
            allData = template_record;
            consoleLog('template_record es 2', template_record);
            consoleLog('template_record es 2 status', template_record, template_status);
            //if document is not in elasticsearch or need to import the doc
            if(Object.keys(template_record).length == 0 || template_record.create_on_elasticsearch == 1 || doc_found){
                consoleLog('template_record es x');
                template_record = await API.graphql({
                    query: getDocumentWorkOrderData,
                    variables: {
                        doc_id: doc_id
                    }
                });
                let doc_data = template_record.data.getDocumentWorkOrderData;
                consoleLog("template_record db 2: ", doc_data);
                allData = JSON.parse(doc_data);
                consoleLog('template_record', allData);
                consoleLog('template_record', allData.user_id);
                consoleLog('template_record', allData.team_id);
                consoleLog('template_record', doc_id);
                if(template_status != 500){ //create on ES if ES is not down
                    try {
                        const save_elastic_es = API.graphql({
                            query: elasticSearchCreateDocOrTemp,
                            variables: {
                                id: doc_id,
                                index: 'prodocs-documents',
                                template: doc_data
                            }
                        });
                        consoleLog('template_record save_elastic_es', save_elastic_es);
                    } catch (err) {
                        console.error(err);
                    }
                    if(es_down_docs.length > 0){
                        let x = es_down_docs.find(d =>d.doc_id == doc_id);
                        consoleLog('es_down_docs x>>>', x);
                        if(x?.es_down_flag == '1'){
                            let temp_docs = es_down_docs.filter(d =>d.doc_id != doc_id);
                            consoleLog('es_down_docs temp_docs>>>', temp_docs);

                            let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                            consoleLog('es_down_docs temp_docs_temp>>>', temp_docs_temp);
                            temp_docs_temp.body.docs = temp_docs;
                            consoleLog('es_down_docs temp_docs_temp final>>>', temp_docs_temp);
                            secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
                            //now set es flag off in db
                            const set_flag_off = API.graphql({
                                query: setEsDownFlagDoc,
                                variables: {
                                    doc_id: doc_id,
                                    flag: '0'
                                }
                            });
                        }
                    }
                }
                await saveQuantities(doc_id, doc_type_id, doc_data, allData);
                
            }else{
                consoleLog('template_record allData', allData);
                allData.structures = template_record.structures;
                if(typeof template_record.markups == 'string'){
                    allData.markups = JSON.parse(template_record.markups);
                }else{
                    allData.markups = template_record.markups;
                }
                allData.notes = template_record.notes;
                allData.options = template_record.options;
                allData.footer = template_record.footer;
                allData.label = template_record.label;
                allData.team_id = template_record.team_id;
                allData.user_id = template_record.user_id;
                consoleLog('template_record str', template_record.structures);
                consoleLog('template_record allDatax', allData);
            }
            template_info = {
                "template_id": template_id,
                "doc_type_id" : doc_type_id,
            }
            
            allData.doc_id = doc_id;
            let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
            let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
            let team_role = JSON.parse(secureLocalStorage.getItem('client_data')).team_role;
            let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

            consoleLog('template_recordz', client_id);
            consoleLog('template_recordz', team_id);

            if(team_role == 'Leader' || team_role == 'Admin'){
                if(allData.team_id != team_id ){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }else if((team_role == '' || team_role == null) && client_id == leader_id){
                if(allData.team_id != team_id ){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }else{
                if(allData.user_id != client_id){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }
        }else if(doc_type_id == 1){ //  will run in case of Document Estimate

            template_record = await API.graphql({
                query: elasticSearchFetchDoc,
                variables: {
                    doc_id: doc_id
                }
            });
            let template_status = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchDoc)).status;
            template_record = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchDoc)).data;
            allData = template_record;
            consoleLog('template_record es 1', template_record);
            consoleLog('template_record es 1 status', template_status);

            if(Object.keys(template_record).length == 0 || template_record.create_on_elasticsearch == 1 || doc_found){
                consoleLog('template_record es x');
                template_record = await API.graphql({
                    query: getDocumentEstimateData,
                    variables: {
                        doc_id: doc_id
                    }
                });
                let doc_data = template_record.data.getDocumentEstimateData;
                consoleLog("template_record db 1: ", doc_data);
                allData = JSON.parse(doc_data);
                consoleLog('template_record', allData);
                consoleLog('template_record', allData.user_id);
                consoleLog('template_record', allData.team_id);
                consoleLog('template_record', doc_id);
                if(template_status != 500){ //create on ES if ES is not down
                    try {
                        const save_elastic_es = API.graphql({
                            query: elasticSearchCreateDocOrTemp,
                            variables: {
                                id: doc_id,
                                index: 'prodocs-documents',
                                template: doc_data
                            }
                        });
                        consoleLog('template_record save_elastic_es', save_elastic_es);
                    } catch (err) {
                        console.error(err);
                    }
                    if(es_down_docs.length > 0){
                        let x = es_down_docs.find(d =>d.doc_id == doc_id);
                        consoleLog('es_down_docs x>>>', x);
                        if(x?.es_down_flag == '1'){
                            let temp_docs = es_down_docs.filter(d =>d.doc_id != doc_id);
                            consoleLog('es_down_docs temp_docs>>>', temp_docs);

                            let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                            consoleLog('es_down_docs temp_docs_temp>>>', temp_docs_temp);
                            temp_docs_temp.body.docs = temp_docs;
                            consoleLog('es_down_docs temp_docs_temp final>>>', temp_docs_temp);
                            secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
                            //now set es flag off in db
                            const set_flag_off = API.graphql({
                                query: setEsDownFlagDoc,
                                variables: {
                                    doc_id: doc_id,
                                    flag: '0'
                                }
                            });
                        }
                    }
                }
                await saveQuantities(doc_id, doc_type_id, doc_data, allData);
            }else{
                consoleLog('template_record allData', allData);
                allData.structures = template_record.structures;
                if(typeof template_record.markups == 'string'){
                    allData.markups = JSON.parse(template_record.markups);
                }else{
                    allData.markups = template_record.markups;
                }
                allData.notes = template_record.notes;
                allData.options = template_record.options;
                allData.footer = template_record.footer;
                allData.label = template_record.label;
                allData.team_id = template_record.team_id;
                allData.user_id = template_record.user_id;
                consoleLog('template_record str', template_record.structures);
                consoleLog('template_record allDatax', allData);
            }
            template_info = {
                "template_id": template_id,
                "doc_type_id" : doc_type_id,
            }
            allData.doc_id = doc_id;
            let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
            let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
            let team_role = JSON.parse(secureLocalStorage.getItem('client_data')).team_role;
            let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

            consoleLog('template_recordz', client_id);
            consoleLog('template_recordz', team_id);

            if(team_role == 'Leader' || team_role == 'Admin'){
                if(allData.team_id != team_id ){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }else if((team_role == '' || team_role == null) && client_id == leader_id){
                if(allData.team_id != team_id ){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }else{
                if(allData.user_id != client_id){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }
        }
        else{   // will run in case of Templates (Estimate / Work Order)

            template_record = await API.graphql({
                query: elasticSearchFetchTemp,
                variables: {
                    template_id: template_id
                }
            });
            let template_status = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchTemp)).status;
            template_record = JSON.parse(JSON.parse(template_record.data.elasticSearchFetchTemp)).data;
            consoleLog('template_record es', template_record);
            consoleLog('template_record es status', template_status);

            if(Object.keys(template_record).length == 0 || temp_found){
                template_record = await API.graphql({
                    query: getTemplate,
                    variables: {
                        template_id: template_id
                    }
                });
                consoleLog("template_record db: ", template_record.data.getTemplate);
                template_record = template_record.data.getTemplate;
                allData = JSON.parse(template_record.data);
                consoleLog('template_record allData', allData);

                //set line_items = [] when a new template is created 
                allData.structures.forEach(structure => {
                    structure.categories.forEach(category => {
                        category.line_items = [];
                    });
                });
                consoleLog('template_record allData line_items = []', allData);

                let temp_for_elastic = allData;
                temp_for_elastic.template_id = template_record.template_id;
                temp_for_elastic.doc_type_id = template_record.doc_type_id;
                temp_for_elastic.user_id = template_record.user_id;
                temp_for_elastic.team_id = template_record.team_id;
                temp_for_elastic.shared = template_record.shared;
                temp_for_elastic.name = template_record.name;
                temp_for_elastic.date_created = template_record.date_created;
                temp_for_elastic.date_updated = template_record.date_updated;
                temp_for_elastic.description = template_record.description;
                temp_for_elastic.update_user_id = template_record.update_user_id;

                consoleLog('template_record temp_for_elastic', temp_for_elastic);

                let temp_for_elasticx = JSON.stringify(temp_for_elastic);
                if(template_status != 500){ //create on ES if ES is not down
                    try {
                        const save_elastic_es = API.graphql({
                            query: elasticSearchCreateDocOrTemp,
                            variables: {
                                id: template_id,
                                index: 'prodocs-templates',
                                template: temp_for_elasticx
                            }
                        });
                        consoleLog('template_record save_elastic_es', save_elastic_es);
                    } catch (err) {
                        console.error(err);
                    }
                    if(es_down_temps.length > 0){
                        let x = es_down_temps.find(d =>d.template_id == template_id);
                        consoleLog('es_down_docs x>>>', x);
                        if(x?.es_down_flag == '1'){
                            let temp_docs = es_down_temps.filter(d =>d.template_id != template_id);
                            consoleLog('es_down_docs temp_docs>>>', temp_docs);

                            let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                            consoleLog('es_down_docs temp_docs_temp>>>', temp_docs_temp);
                            temp_docs_temp.body.temps = temp_docs;
                            consoleLog('es_down_docs temp_docs_temp final>>>', temp_docs_temp);
                            secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
                            //set es flag off in db
                            const set_flag_off = API.graphql({
                                query: setEsDownFlagTemp,
                                variables: {
                                    template_id: template_id,
                                    flag: '0'
                                }
                            });
                        }
                    }
                }else{
                    let flag = JSON.parse(secureLocalStorage.getItem("es_down_template_created_flag"));
                    consoleLog('xhandle_es_down flag>>>', flag);

                    if(typeof flag != 'undefined' && flag != '' && flag != null){
                        let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
                        es_down_docs.push({template_id : template_id, es_down_flag : '2'});
                        consoleLog('xhandle_es_down es_down_docs>>>', es_down_docs);
                        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                        consoleLog('xhandle_es_down temp_docs_temp>>>', temp_docs_temp);

                        temp_docs_temp.body.temps = es_down_docs;
                        consoleLog('xhandle_es_down temp_docs_temp final>>>', temp_docs_temp);
                        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
                        const set_flag_off = API.graphql({ //set es flag 2 for create in db
                            query: setEsDownFlagTemp,
                            variables: {
                                template_id: template_id,
                                flag: '2'
                            }
                        });
                        secureLocalStorage.removeItem('es_down_template_created_flag');
                        consoleLog('xhandle_es_down flag>>>', flag);
                    }
                }
            }else{
                consoleLog('template_record allData', allData);
                allData = template_record;
                if(typeof template_record.markups == 'string'){
                    allData.markups = JSON.parse(template_record.markups);
                }else{
                    allData.markups = template_record.markups;
                }
                // allData.structures = template_record.structures;
                // allData.markups = template_record.markups;
                // allData.notes = template_record.notes;
                // allData.options = template_record.options;
                // allData.footer = template_record.footer;
                // allData.label = template_record.label;
                // consoleLog('template_record str', template_record.structures);
                consoleLog('template_record allDatax', allData);
            }

            template_info = {
                "template_id": template_record.template_id,
                "doc_type_id" : template_record.doc_type_id,
                "user_id" : template_record.user_id,
                "team_id" : template_record.team_id,
                "name": template_record.name,
                "description": template_record.description,
                "date_created": template_record.date_created,
                "date_updated": template_record.date_updated,
            };

            let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
            let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
            let team_role = JSON.parse(secureLocalStorage.getItem('client_data')).team_role;
            let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

            consoleLog('template_recordx', template_record.user_id);
            consoleLog('template_recordx', template_record.team_id);
            consoleLog('template_recordz', client_id);
            consoleLog('template_recordz', team_id);

            if(template_record.user_id != client_id){
                const result = await API.graphql({
                    query: getClientByID,
                    variables: {
                        client_id: template_record.user_id
                    }
                });
                consoleLog('xxxxxx>', result.data.getClientByID);
                let edit_res = result.data.getClientByID.pd_team_edit;
                consoleLog('xxxxxx>', edit_res);
                if(edit_res == 0){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }

            if(team_role == 'Leader' || team_role == 'Admin'){
                if(template_record.team_id != team_id){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }else if((team_role == '' || team_role == null) && client_id == leader_id){
                if(allData.team_id != team_id ){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }else{
                if(template_record.user_id != client_id){
                    alert('You are not authorized to access this Template.');
                    return false;
                }
            }
           
        }//will run in case of Templates (Estimate / Work Order)

        consoleLog("template_info for redux: " , template_info);
        dispatch({ type: "TEMPLATE_INFO", value: template_info });

        // allData = (template_id == 0 ) ? JSON.parse(template_record.data.getDocumentWorkOrderData) : JSON.parse(template_record.data);
        consoleLog("allData for redux: " , allData);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        consoleLog("end of function");
        setLoading(false);
    }

    async function saveQuantities(doc_id, doc_type_id, doc_data, allData) {
        const getDocOrders = await API.graphql({
            query: getDocOrderIds,
            variables: {
                doc_id: doc_id
            }
        });
        
        let ordersData = JSON.parse(getDocOrders.data.getDocOrderIds);
        
        let scopeIds = [];
        for(const order of ordersData) {
            scopeIds.push(order.scope_id);
        }

        if(scopeIds.length > 0) {
            const addQuantities = await API.graphql({
                query: addDocOrders,
                variables: {
                    doc_id: doc_id,
                    doc_type_id : doc_type_id, 
                    scope_ids: scopeIds.join(','),
                    data: doc_data,
                    token: secureLocalStorage.getItem("user_token"),
                    action: 'legacy'
                }
            });
            
            const response = JSON.parse(addQuantities.data.addDocOrders);
            if(response.statusCode === 200) {
                allData.quantities = response.quantities; 
            }
        }
    }

    async function fetchUnits() {
        consoleLog("fetchUnits")
        const default_units = await API.graphql({
            query: getUnits
        })
        consoleLog("default_units: ", default_units);

        // set default categories to redux
        let d_units = default_units.data.getUnits;
        let temp_unit = {};
        temp_unit.unit_id=0;
        temp_unit.name='';
        temp_unit.label='(None)';
        d_units.unshift(temp_unit);
        consoleLog("default_units: ", d_units);
        dispatch({ type: "DEFAULT_UNITS", value: d_units });
    }

    async function fetchCategories() {
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        consoleLog("client_id: ", client_id);
        consoleLog("team_id: ", team_id);

        const userCategories = await API.graphql({
            query: getCategoryByUserID,
            variables: {
                user_id: client_id,
                team_id: team_id
            }
        });

        const rd_categories = await API.graphql({
            query: getCategoriesWithLineitemsRedis,
            variables: {
                id: 1
            }
        });
        consoleLog('rd_categories>', rd_categories);
        let rd_categoriesx = JSON.parse(rd_categories.data.getCategoriesWithLineitemsRedis);
        let rd_categoriesxx = JSON.parse(rd_categoriesx.body);
        consoleLog('rd_categories>', rd_categoriesxx);

        if(!rd_categoriesxx){
            const d_categories = await API.graphql({
                query: getDefaultCategories
            });
            // set default categories to redux
            let d_cat = default_category;
            d_cat = d_categories.data.getDefaultCategories;
            dispatch({ type: "DEFAULT_CATEGORIES", value: d_cat });
            consoleLog("setUserDefaultCategory: ", d_categories.data.getDefaultCategories);

            // set user categories to redux
            let cat_by_userid = category_by_user_id;
            cat_by_userid = userCategories.data.getCategoryByUserID;
            dispatch({ type: "CATEGORIES_BY_USERID", value: cat_by_userid });

            consoleLog("setUserCategory: ", userCategories);
            setUserDefaultCategory(d_categories.data.getDefaultCategories);
            setUserCategory(userCategories.data.getCategoryByUserID);
        }else{
            dispatch({ type: "DEFAULT_CATEGORIES", value: rd_categoriesxx });

            // set user categories to redux
            let cat_by_userid = category_by_user_id;
            cat_by_userid = userCategories.data.getCategoryByUserID;
            dispatch({ type: "CATEGORIES_BY_USERID", value: cat_by_userid });

            consoleLog("setUserCategory: ", userCategories);
            setUserDefaultCategory(rd_categoriesxx);
            setUserCategory(userCategories.data.getCategoryByUserID);
        }
    }

    async function fetchDefaultManufacturer() { // redis
        if(url_params.doc_type_id == 2 || typeof url_params.doc_type_id == 'undefined'){
            let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
            const user_man = await API.graphql({
                query: getUsersManufacturers,
                variables: {
                    team_id: team_id
                }
            });
            let user_man_data = JSON.parse(user_man.data.getUsersManufacturers);
            consoleLog('fetchDefaultManufacturer user_man>', user_man_data);
            let temp_user_man_data = [];
            user_man_data.map( function(d_manufecturer, i) {
                temp_user_man_data.push({
                    'value' : d_manufecturer.manufacturer_id,
                    'label' : d_manufecturer.name
                });
            });
            setUserManufacturers(temp_user_man_data);
        }
        const dr_man = await API.graphql({
            query: getDefaultManufacturersRedis,
            variables: {
                id: 1
            }
        });
        let dr_manx = JSON.parse(dr_man.data.getDefaultManufacturersRedis);
        consoleLog('fetchDefaultManufacturer dr_man', dr_manx.body);
        consoleLog('fetchDefaultManufacturer dr_man', typeof dr_manx.body);

        if(dr_manx.body == null){
            const d_man = await API.graphql({
                query: getDefaultManufacturer,
            });
    
            let default_manufecturer = d_man.data.getDefaultManufacturer;
            var temp_default_manufecturer = [];
            default_manufecturer.map( function(d_manufecturer, i) {
                temp_default_manufecturer.push({
                    'value' : d_manufecturer.manufacturer_id,
                    'label' : d_manufecturer.name
                });
            });
            setDefaultManufacturers(temp_default_manufecturer);

            consoleLog('fetchDefaultManufacturer if', temp_default_manufecturer);
    
            // set default manufecturer to redux
            let d_manu = d_manf;
            d_manu = temp_default_manufecturer;
            dispatch({ type: "MANUFECTURER", value: d_manu });
        }else{
            let default_manufecturer = JSON.parse(dr_manx.body);
            var temp_default_manufecturer = [];
            default_manufecturer.map( function(d_manufecturer, i) {
                temp_default_manufecturer.push({
                    'value' : d_manufecturer.manufacturer_id,
                    'label' : d_manufecturer.name
                });
            });
            consoleLog('fetchDefaultManufacturer else', temp_default_manufecturer);
            setDefaultManufacturers(temp_default_manufecturer);
            dispatch({ type: "MANUFECTURER", value: temp_default_manufecturer });
        }
    }

    return (
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
        { loading ?
            <LoadingContainer>
                <img className="loader" src={Loader}/>
            </LoadingContainer>

        :
            <Template>
                { Array.isArray(defaultCategory) && defaultCategory.length && Array.isArray(userCategory) && Array.isArray(defaultManufacturers) && defaultManufacturers.length ? 
                    <>
                        {
                            show &&
                            <ToastBox
                            setShow={setShow}
                            show={show}
                            Text={showMessage}
                            Type={showMessageType}
                            />
                        }
                        <CreateTemplate
                            setLoading={setLoading}
                            fetchTemplateRecord={fetchTemplateRecord}
                            url_params={url_params}
                            defaultManufacturers={defaultManufacturers}
                            userManufacturers={userManufacturers}
                            setUserManufacturers={setUserManufacturers}
                            prevState={prevState}
                            show={show}
                            showMessage={showMessage}
                            showMessageType={showMessageType}
                            setShow={setShow}
                            setShowMessage={setShowMessage}
                            setShowMessageType={setShowMessageType}
                        />
                    </>
                    : 
                    <></>
                }
            </Template>
        }
        </>
    )
}

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`

const Template = styled.div`
    // padding-top: 112px;
    position: relative;
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    // padding: 5em 0 6.5em 0;

    .toast-body{
        font-size:14px !important;
    }
`
