import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { Accordion, Card, Button, Table, Row, Col } from "react-bootstrap";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { FaCaretDown, FaCircleNotch } from "react-icons/fa";
import { Link } from "gatsby";
import Select, { components } from "react-select";
import LineItem from "./LineItem";
import { isEmpty } from '../../Components/CommonComponents/helper';
import ScopeTypes from "../../../src/constants/ScopeTypes";
import ScopeUnits from '../../../src/constants/ScopeUnits';
import {
  getManufacturerLineItemRedis,
  getDefaultManufacturer,
  getLineItemByMan,
  getLineItemByID,
  createDocLineItem,
  createLineItem,
  deleteDocumentCategory,
  updateDocCategoryName,
  updateDocLineItemSortOrder,
  getLineItemByIDs,
  searchAllLineItems
} from "../../graphql/queries";
import EdiText from "react-editext";
import Cancel from "../../assets/cancel_line.svg";
import Plus from "../../assets/plus.svg";
import ExpandUp from "../../assets/expand.svg";
import ExpandDown from "../../assets/down-arrow.svg";
import CLight from "../../assets/add_cus.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import Move from "../../assets/move.svg";
import Loaderx from "../../assets/pageload.gif";
import { FaTrashAlt } from "react-icons/fa";
import { GoKebabVertical } from "react-icons/go";
import  secureLocalStorage  from  "react-secure-storage";

Amplify.configure(config);
const CaretDownIcon = () => {
  return <FaCaretDown color={"#000"} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export default function Category(props) {
  const {consoleLog} = require('../commonFunctions.js');
  consoleLog("Category props: ", props);
  consoleLog("Category props.category.line_items: ", props.category.line_items);

  const updatedStructure = useSelector((state) => state.templateStructure);
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  let options;
  if(typeof stData.options == 'string'){
    options = JSON.parse(stData.options);
    if(options?.show_category_price){
      let show_category_price = parseInt(options.show_category_price);
      consoleLog('show_category_price if options.show_category_price>', options?.show_category_price);
      consoleLog('show_category_price if show_category_price>', show_category_price);
      props.setDisplayCategoryPrice(show_category_price);
    }
  }else{
    options = stData.options;
    if(stData?.options?.show_category_price){
      let show_category_price = stData?.options?.show_category_price;
      consoleLog('show_structure_price else stData?.options?.show_structure_price>', stData?.options?.show_structure_price);
      consoleLog('show_structure_price else show_structure_price>', show_category_price);
      props.setDisplayCategoryPrice(show_category_price);
    }
  }

  const [structureIndex, setStructureIndex] = React.useState(
    props.structureIndex
  );
  const [defaultManufacturers, setDefaultManufacturers] = React.useState(
    useSelector((state) => state.defaultManufacturer)
  );

  const [default_category, setDCategory] = React.useState(useSelector((state) => state.defaultCategories));

  const dispatch = useDispatch();

  const [manufacturersLineItems, setManufacturersLineItems] = React.useState([]);
  const [lineItemAdd, setLineItemAdd] = useState(false);
  const [showAddCat, toggleShowAddCat] = React.useState(true);

  const [manufecturerValue, setManufecturerValue] = React.useState("Select");
  const [lineItems, setLineItems] = React.useState(
    props.category.line_items ? props.category.line_items : []
  );

  const [manufecturerLineItemToggle, setManufecturerLineItemToggle] =
    React.useState(false);
  const [lineItemSearchText, setLineItemSearchText] = React.useState("");
  const [showLineItem, toggleShowLineItem] = React.useState(false);
  const [inputList, setInputList] = React.useState([]);
  const [dragPositionCategory, setDragPositionCategory] = useState(lineItems);

  const [lineItemSearchLoader, setLineItemSearchLoader] = useState(false);
  const [lineItemAddLoader, setLineItemAddLoader] = useState(false);

  const [reportTypes, setReportTypes] = React.useState([]);
  const [reportUnits, setReportUnits] = React.useState([]);
  let df_units = useSelector((state) => state.defaultUnits);
  const [defaultUnits, setDefaultUnits] = React.useState(df_units);
  
  const [selectedScopeCalc, setSelectedScopeCalc] = React.useState({});
  const [selectedScopeType, setSelectedScopeType] = React.useState({});

  const [categoryTotal, setCategoryTotal] = React.useState(0);

  let report_types = [{ value: "(None)", label: "(None)" }];
  for (let i = 0; i < ScopeTypes.length; i++) {
      report_types.push({
          value: ScopeTypes[i].id,
          label: ScopeTypes[i].title,
      });
  }

  function calculateCategoryTotal() {
    let allData = props.category;
    consoleLog("calculateCategoryTotal", allData);
    let k;
    let total = 0;
      for (k = 0; k < allData.line_items.length; k++) {
        if(typeof allData.line_items[k].net_cost == 'undefined'){
          allData.line_items[k].net_cost = 0;
        }
        if (allData.line_items[k].unit != "") {
          total = total + 1 * allData.line_items[k].net_cost;
        }
      }
    total = Number.parseFloat(total).toFixed(2);
    consoleLog("calculateCategoryTotal", total);
    setCategoryTotal(total);
  }

  useEffect(() => {
    calculateCategoryTotal();
    setStData(updatedStructure);
    setLineItems(props.category.line_items ? props.category.line_items : []);
    setStructureIndex(props.structureIndex);
    setDragPositionCategory(props.category.line_items);
    consoleLog("Category on useEffect sturctureIndex: ", structureIndex);
    setReportTypes(report_types);
  }, [
    updatedStructure,
    props.category.line_items,
    structureIndex,
    props.category.line_items,
  ]);

  function handleAddItemAdd() {
    if (lineItemSearchText == '') {
      props.setShow(true);
      props.setShowMessage('Please enter Line Item name.');
      props.setShowMessageType('Error');
      return false;
    }
    else {
      setLineItemAdd(true);
    }
  }

  /////
  async function fetchAlgo(scopeType) {
    let scopes = [];
    let type = scopeType;

    if (scopeType === 'RoofScopeX' || scopeType === 'RoofScope+') {
      type = 'RoofScope';
    }

    let reportUnits = [{ value: "(None)", label: "(None)" }];
    let scopetUnits = ScopeUnits[type];

    if (scopeType === 'RoofScope+') {
        scopetUnits = { ...scopetUnits, ...ScopeUnits['GutterScope'] }
    }

    if (!isEmpty(stData.quantities)) {
      let quantities = stData.quantities;

      if (typeof quantities === 'string') {
          quantities = JSON.parse(stData.quantities);
      }

      let structure = quantities && quantities.length
          ? quantities.find(quantity => quantity.structure_id == props.structureId)
          : null;

      if (!isEmpty(structure)) {
        scopes = structure.scopes;
        let quantityList = scopes && scopes.length
          ? scopes.find(scope => scope.scope_type_name.toLowerCase() == type.toLowerCase())
          : null;

        if (!isEmpty(quantityList)) {
          scopetUnits = { ...scopetUnits, ...quantityList.quantities };
        }

        if (scopeType === 'RoofScope+') {
          let gutterScopeList = scopes && scopes.length
            ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'gutterscope')
            : null;

          if (!isEmpty(gutterScopeList)) {
            scopetUnits = { ...scopetUnits, ...gutterScopeList.quantities };
          }
        }
      }
    }

    for (const [key, value] of Object.entries(scopetUnits)) {
      if (scopeType === 'RoofScopeX' && key !== 'total_squares' && isNaN(parseInt(value.name))) {
          continue;
      }
      reportUnits.push({
          value: value.algo,
          label: `${value.name} (${value.unit})`,
          unit: value.unit
      });
    }

    if (scopes.length > 0 && type !== 'RoofScope') {
      let roofScopeList = scopes && scopes.length
        ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'roofscope')
        : null;

      if (!isEmpty(roofScopeList)) {
        for (const [key, value] of Object.entries(roofScopeList.quantities)) {
          if (isNaN(parseInt(value.name))) {
              continue;
          }
          reportUnits.push({
              value: value.algo,
              label: `${value.name}${value.unit})`,
              unit: value.unit
        });
        }
      }
    }
    setReportUnits(reportUnits);
  }
  const handleChangeScopeType = (e) => {
    consoleLog("handleChangeScopeType e: ", e);
    if (e.label !== '(None)') {
      setSelectedScopeType(e);
      fetchAlgo(e.label);
    }
  }
  const handleChangeScopeCalc = (e) => {
    if (typeof e === 'object') {
      consoleLog("handleChangeScopeCalc e: ", e);
      setSelectedScopeCalc(e);
    }
  };
  ////

  async function manufecturerLineItems(manufacturer_id) { // redis
    consoleLog("manufecturerLineItems: manufacturer_id: ", manufacturer_id);
    const r_menufecturer_line_items = await API.graphql({
      query: getManufacturerLineItemRedis,
      variables: {
        id: manufacturer_id,
      },
    });
    let rm_lines = JSON.parse(r_menufecturer_line_items.data.getManufacturerLineItemRedis);
    consoleLog("rd_menufecturer_line_items: ", rm_lines);

    if(rm_lines.body == null){
      const menufecturer_line_items = await API.graphql({
        query: getLineItemByMan,
        variables: {
          manufacturer_id: manufacturer_id,
        },
      });
      consoleLog("menufecturer_line_items: if:", menufecturer_line_items.data.getLineItemByMan);
      setManufacturersLineItems(menufecturer_line_items.data.getLineItemByMan);
    }else{
      let rm_linex = JSON.parse(rm_lines.body);
      consoleLog("menufecturer_line_items: else:", rm_linex);
      setManufacturersLineItems(rm_linex);
    }
  }

  async function manufecturerLineItemsById(line_item_id) {
    /// add a line item from select
    consoleLog("line_item_id: ", line_item_id);
    const menufecturer_line_items_by_id = await API.graphql({
      query: getLineItemByID,
      variables: {
        line_item_id: line_item_id,
      },
    });

    let adj_quantity =
      1 * menufecturer_line_items_by_id.data.getLineItemByID.adjusted_quantity;
    let cost = 1 * menufecturer_line_items_by_id.data.getLineItemByID.cost;

    if (
      menufecturer_line_items_by_id.data.getLineItemByID.sub_line_item == "[]"
    ) {
      menufecturer_line_items_by_id.data.getLineItemByID.sub_line_item = [];
    }

    let netCost = adj_quantity * cost;

    // netCost =  Math.round(netCost * 100) / 100;
    netCost = Number.parseFloat(netCost).toFixed(2);

    let temp_object = {};

    temp_object.sort_order = 0;
    temp_object.net_cost = netCost;

    let li_object = Object.assign(
      menufecturer_line_items_by_id.data.getLineItemByID,
      temp_object
    );

    consoleLog(
      "menufecturer_line_items_by_id: ",
      menufecturer_line_items_by_id.data.getLineItemByID
    );
    consoleLog("menufecturer_line_items_by_id: ", li_object);
    return menufecturer_line_items_by_id.data.getLineItemByID;
  }

  function handleManufecturerChange(e) {
    // when we select a manufacturer from the dropdown
    consoleLog("handleManufecturerChange e.value: ", e.value);
    setManufecturerValue(e.value);
    if(typeof e.value != 'string'){
      manufecturerLineItems(e.value);
    }else{
      searchAllLineItemsFunc(lineItemSearchText);
    }
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    consoleLog(index, list[index]);
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { notes: "" }]);
  };

  function handleLineItemChange(line_item_id) {
    consoleLog("handleLineItemChange line_item_id: ", line_item_id);
    var lineItems_copy = [...lineItems];
    lineItems_copy = lineItems_copy.filter(
      (lic) => lic.line_item_id !== line_item_id
    );
    consoleLog("handleLineItemChange lineItems_copy: ", lineItems_copy);
    setLineItems(lineItems_copy);
    consoleLog("handleLineItemChange lineItems_copy: ", lineItems_copy);

    let allData = { ...stData };
    allData.structures[structureIndex].categories.find(
      (c) => c.category_id == props.category.category_id
    ).line_items = [...lineItems_copy];
    consoleLog("OnHandleLineItemChange: ", allData);
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
  }

  async function searchAllLineItemsFunc(search){
    if(search != ''){
      setLineItemSearchLoader(true);
      consoleLog('searchAllLineItemsFunc',defaultManufacturers);
      let def_arr = [];
      for (let def of defaultManufacturers) {
        def_arr.push(def.value);
      }
      consoleLog('searchAllLineItemsFunc',def_arr.join());
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      const mu_line_items = await API.graphql({
        query: searchAllLineItems,
        variables: {
          client_id: client_id,
          team_id: team_id,
          search: search,
          man_ids: def_arr.join()
        },
      });
      consoleLog('searchAllLineItemsFunc',mu_line_items);
      let mu_line_itemx = JSON.parse(mu_line_items.data.searchAllLineItems);
      consoleLog('searchAllLineItemsFunc',mu_line_itemx.body);
      setManufecturerLineItemToggle(true);
      setManufacturersLineItems(mu_line_itemx.body);
      setLineItemSearchLoader(false);
    }
  }

  async function handleLineItemChangeText(e) {
    consoleLog('handleLineItemChangeText>',manufecturerValue);
    if(e.target.value == "" && manufecturerValue == 'Select'){
      consoleLog('handleLineItemChangeText> 1', e.target.value);
      setLineItemSearchText(e.target.value);
      setManufecturerLineItemToggle(false);
    }
    if(e.target.value == "" && manufecturerValue != 'Select'){
      consoleLog('handleLineItemChangeText> 2', e.target.value);
      setLineItemSearchText(e.target.value);
      setManufecturerLineItemToggle(false);
    }
    if(e.target.value != "" && manufecturerValue == 'Select'){
      consoleLog('handleLineItemChangeText> 3', e.target.value);
      setLineItemSearchText(e.target.value);
      // setManufecturerLineItemToggle(false);
      searchAllLineItemsFunc(e.target.value);
    }
    if(e.target.value != "" && manufecturerValue != 'Select'){
      consoleLog('handleLineItemChangeText> 4', e.target.value);
      setLineItemSearchText(e.target.value);
      setManufecturerLineItemToggle(true);
    }
  }

  function resetNewLineData(){
    setSelectedScopeCalc({});
    setSelectedScopeType({});
}

  async function handleCreateLineItem() { // Create a custom line item
      consoleLog('handleCreateLineItem lineItemSearchText>', lineItemSearchText.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-'));
      consoleLog('handleCreateLineItem selectedScopeType>', selectedScopeType);
      consoleLog('handleCreateLineItem selectedScopeCalc>', selectedScopeCalc);
      
      if(lineItemSearchText == ''){
        props.setShow(true);
        props.setShowMessage('Please enter Line Item name.');
        props.setShowMessageType('Error');
        return false;
      }
      let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
      let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
      let li_name = lineItemSearchText;
      setLineItemSearchText('');
      setManufecturerLineItemToggle(false);
      setManufecturerValue('Select');
      toggleShowLineItem(!showLineItem);
      let allData = { ...stData };

      const create_line_item = await API.graphql({ // Create a line item in am_est_line_item table
          query: createLineItem,
          variables: {
            doc_type_id: typeof props.url_params.doc_type_id != 'undefined' ? props.url_params.doc_type_id : allData.doc_type_id,
            manufacturer_id: 0,
            sku: '',
            scope_type_id: 0, //
            line_item_code: li_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-'),
            name: li_name,
            description: "",
            cost: 0,
            quantity: 0,
            adjusted_quantity: 0,
            unit: selectedScopeCalc.unit ? selectedScopeCalc.unit : 'SQ',
            convert_to: "",
            ratio_from: 0,//
            ratio_to: 0,//
            convert_round: "0",//
            algorithm: selectedScopeCalc.value ? selectedScopeCalc.value : '',
            labor_cost: 0,
            material_cost: 0,
            profit: 0,
            overhead: 0,
            waste: 0,//
            notes: '',
            sub_line_item: '[]',
            manufacturer: '',
            apply_op: 1,
            product: '',
            team_id: team_id,
            user_id: client_id,
            status: 1
          }
      });
      
      let line_item_id = JSON.parse(create_line_item.data.createLineItem).insertId; // Get the id of newly created line item

      setLineItemAdd(false);
      
      consoleLog('handleCreateLineItem line_item_id',line_item_id);
      consoleLog('handleCreateLineItem props.url_params.doc_id',props.url_params.doc_id);
            
      let new_line_item = {
        line_item_id: line_item_id,
        manufacturer_id: 0,
        sku: '',
        scope_type_id: 0,
        line_item_code: li_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-'),
        name: li_name,
        description: "",
        cost: 0,
        quantity: 0,
        adjusted_quantity: 0,
        unit: selectedScopeCalc.unit ? selectedScopeCalc.unit : 'SQ',
        convert_to: "",
        ratio_from: 0,
        ratio_to: 0,
        convert_round: 0,
        algorithm: selectedScopeCalc.value ? selectedScopeCalc.value : '',
        labor_cost: 0,
        material_cost: 0,
        profit: 0,
        overhead: 0,
        waste: 0,
        notes: '',
        sub_line_item: '[]',
        manufacturer: '',
        apply_op: 1,
        product: '',
        color:'', //new
        remove_prices: 0,
        sort_order: 0,
        status: 1
      } // Display on the front end
      if(typeof props.url_params.doc_id != 'undefined'){
        let doc_cat_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_category_id;
        let doc_struct_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_structure_id;
        new_line_item.doc_id=props.url_params.doc_id;
        new_line_item.doc_type_id=props.url_params.doc_type_id;
        new_line_item.doc_structure_id=doc_struct_id*(1);
        new_line_item.doc_category_id=doc_cat_id*(1);
      }else{
        new_line_item.doc_type_id=allData.doc_type_id;
      }

      resetNewLineData();

      consoleLog('handleCreateLineItem new_line_item',new_line_item);
      
      let current_line_items = props.category.line_items;
      consoleLog('handleCreateLineItem current_line_items >',current_line_items);

      current_line_items.push(new_line_item);
      consoleLog('handleCreateLineItem current_line_items >>',current_line_items);
      
      allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = current_line_items;
      
      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
      setStData(allData);

      if(typeof props.url_params.doc_id != 'undefined'){
        props.updateDocOnElasticSearch();
      }
      // await handleLineItemSelect(line_item_id);
  }

  async function handleLineItemSelect(selected_line_item_id, selected_line_item) { // Add manufacturer line item
    consoleLog("handleLineItemSelect selected_line_item_id:",selected_line_item_id);
    consoleLog("handleLineItemSelect selected_line_item:",selected_line_item);
    setLineItemAddLoader(true);
    let selected_line_item_size = Object.keys(selected_line_item).length;
    consoleLog("handleLineItemSelect selected_line_item_size:",selected_line_item_size);
    if(selected_line_item_size > 20){
      var new_line_item = selected_line_item;
    }else{
      var new_line_item = await manufecturerLineItemsById(selected_line_item_id); // Get line item
    }

    setLineItemSearchText('');
    setManufecturerLineItemToggle(false);
    setManufecturerValue('Select');
    toggleShowLineItem(!showLineItem);
    setLineItemAddLoader(false);


    consoleLog("handleLineItemSelect new_line_item:",new_line_item);
    
    let allData = stData;

    if(typeof new_line_item.color == 'undefined' || new_line_item.color == null){
      new_line_item.color = '';
    }
    if(typeof new_line_item.manufacturer == 'undefined' || new_line_item.manufacturer == null){
      new_line_item.manufacturer = '';
    }
    if(typeof new_line_item.notes == 'undefined' || new_line_item.notes == null){
      new_line_item.notes = '';
    }
    if(typeof new_line_item.product == 'undefined' || new_line_item.product == null){
      new_line_item.product = '';
    }
    if(typeof new_line_item.remove_prices == 'undefined' || new_line_item.remove_prices == null){
      new_line_item.remove_prices = 0;
    }
    if(typeof new_line_item.sort_order == 'undefined' || new_line_item.sort_order == null){
      new_line_item.sort_order = 0;
    }

    let net_cost = (+new_line_item.adjusted_quantity)*(+new_line_item.cost);
    net_cost = Number.parseFloat(net_cost).toFixed(2);

    let line_item_to_create = {
      line_item_id: new_line_item.line_item_id,
      manufacturer_id: new_line_item.manufacturer_id,
      sku: new_line_item.sku,
      scope_type_id: new_line_item.scope_type_id,
      line_item_code: new_line_item.line_item_code,
      name: new_line_item.name,
      description: new_line_item.description,
      cost: new_line_item.cost,
      quantity: new_line_item.quantity,
      adjusted_quantity: new_line_item.adjusted_quantity,
      unit: new_line_item.unit,
      convert_to: new_line_item.convert_to,
      ratio_from: new_line_item.ratio_from,
      ratio_to: new_line_item.ratio_to,
      convert_round: new_line_item.convert_round*(1),
      algorithm: new_line_item.algorithm,
      labor_cost: new_line_item.labor_cost,
      material_cost: new_line_item.material_cost,
      profit: new_line_item.profit,
      overhead: new_line_item.overhead,
      waste: new_line_item.waste,
      notes: new_line_item.notes,
      sub_line_item: new_line_item.sub_line_item,
      manufacturer: new_line_item.manufacturer,
      apply_op: new_line_item.apply_op,
      product: new_line_item.product,
      color:new_line_item.color,
      remove_prices: new_line_item.remove_prices,
      sort_order: new_line_item.remove_prices,
      status: 1,
      net_cost: net_cost
  } // Display on the front end

  if(typeof props.url_params.doc_id != 'undefined'){
    let doc_cat_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_category_id;
    let doc_struct_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_structure_id;
    line_item_to_create.doc_id=props.url_params.doc_id;
    line_item_to_create.doc_type_id=props.url_params.doc_type_id;
    line_item_to_create.doc_structure_id=doc_struct_id*(1); //new
    line_item_to_create.doc_category_id=doc_cat_id*(1); //new
  }else{
    line_item_to_create.doc_type_id=allData.doc_type_id;
  }

  consoleLog('handleLineItemSelect line_item_to_create >',line_item_to_create);

  let current_line_items = props.category.line_items;
  consoleLog('handleLineItemSelect current_line_items >>',current_line_items);

  current_line_items.push(line_item_to_create);
  consoleLog('handleLineItemSelect current_line_items >>>',current_line_items);

  allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = current_line_items;
  props.calculateSubTotal(allData);
  dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
  setStData(allData);
  if(typeof props.url_params.doc_id != 'undefined'){
    props.updateDocOnElasticSearch();
  }

    // const create_line_item = await API.graphql({
    //   query: createDocLineItem,
    //   variables: {
    //       doc_id:props.url_params.doc_id*(1),
    //       doc_structure_id:doc_struct_id*(1),
    //       doc_category_id:doc_cat_id*(1),
    //       line_item_id:new_line_item.line_item_id,
    //       manufacturer_id:new_line_item.manufacturer_id,
    //       sku:new_line_item.sku,
    //       scope_type_id:new_line_item.scope_type_id,
    //       line_item_code:new_line_item.line_item_code,
    //       name:new_line_item.name,
    //       description:new_line_item.description,
    //       cost:new_line_item.cost,
    //       quantity:new_line_item.quantity,
    //       adjusted_quantity:new_line_item.adjusted_quantity,
    //       unit:new_line_item.unit,
    //       convert_to:new_line_item.convert_to,
    //       ratio_from:new_line_item.ratio_from,
    //       ratio_to:new_line_item.ratio_to,
    //       convert_round:new_line_item.convert_round*(1),
    //       algorithm:new_line_item.algorithm,
    //       labor_cost:new_line_item.labor_cost,
    //       material_cost:new_line_item.material_cost,
    //       profit:new_line_item.profit,
    //       overhead:new_line_item.overhead,
    //       waste:new_line_item.waste,
    //       notes:new_line_item.notes,
    //       sub_line_item:new_line_item.sub_line_item,
    //       manufacturer:new_line_item.manufacturer,
    //       apply_op:new_line_item.apply_op,
    //       product:new_line_item.product,
    //       color:'',
    //       remove_prices:new_line_item.remove_prices,
    //       sort_order:new_line_item.sort_order,
    //   }
    // });

    // let create_line_item_id = JSON.parse(create_line_item.data.createDocLineItem).insertId;
    // consoleLog('handleLineItemSelect create_line_item:',create_line_item_id);
    // new_line_item.doc_line_item_id = create_line_item_id;

    // consoleLog("handleLineItemSelect new_line_item: ",new_line_item);

    // var lineItems_copy = [...lineItems, { ...new_line_item }];
    // consoleLog("handleLineItemSelect lineItems_copy after:", lineItems_copy);
    // setLineItems(lineItems_copy);

    // allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = [...lineItems_copy];
    // dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    
    // consoleLog('handleLineItemSelect doc_cat_id:',doc_cat_id);
    // consoleLog('handleLineItemSelect doc_struct_id:',doc_struct_id);
    // consoleLog('handleLineItemSelect props.url_params.doc_type_id:',props.url_params.doc_type_id);
    // consoleLog('handleLineItemSelect props.category.category_id:',props.category.category_id);
  }

  async function updateCategoryTitle(updated_title){
    consoleLog("updateCategoryTitle updated_title: ", updated_title);
    consoleLog(
      "updateCategoryTitle props.category.category_id: ",
      props.category.category_id
    );

    let allData = { ...stData };
    allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).name = updated_title;
    
    let id_to_update = allData.structures[structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_category_id;
    props.onChange(allData);

    props.setShow(true);
    props.setShowMessage('Category title has been updated.');
    props.setShowMessageType('Success');

    consoleLog("updateCategoryTitle id_to_update: ", id_to_update);

    if(typeof props.url_params.doc_id != 'undefined'){
      const update_cat_name = await API.graphql({
        query: updateDocCategoryName,
        variables: {
          name: updated_title,
          doc_category_id: id_to_update
        }
      });
      consoleLog("updateCategoryTitle update_cat_name: ", update_cat_name);
      props.updateDocOnElasticSearch();
    }
  };

  async function removeCategory() { //to remove category in estimate
    consoleLog("removeCategory category_id: ", props.category.category_id);
    consoleLog("removeCategory structureIndex: ", structureIndex);

    let allData = { ...stData };

    let id_to_del = allData.structures[structureIndex].categories.find(c => c.category_id == props.category.category_id);
    consoleLog('removeCategory id:',id_to_del.doc_category_id);

    allData.structures[structureIndex].categories = allData.structures[structureIndex].categories.filter((c) => c.category_id != props.category.category_id);
    props.onChange(allData);

    props.calculateSubTotal(allData);

    if(typeof props.url_params.doc_id != 'undefined'){
      const del_category = await API.graphql({
          query: deleteDocumentCategory,
          variables: {
              doc_category_id: id_to_del.doc_category_id
          }
      });
      consoleLog('removeCategory >',del_category);
      props.updateDocOnElasticSearch(); //done
    }
  }

  function ShowLineItemRender() {
    toggleShowLineItem(!showLineItem);
  }

  function handleOnDragEndLineItem(results) {
    consoleLog("handleOnDragEndLineItem results: ", results);
    if (!results.destination) return;

    const itemsCategory = Array.from(dragPositionCategory);
    const [reorderedItemCategory] = itemsCategory.splice(
      results.source.index,
      1
    );
    itemsCategory.splice(results.destination.index, 0, reorderedItemCategory);

    if(typeof props.url_params.doc_id != 'undefined'){
      itemsCategory.forEach((item, i)=>{
        item.sort_order = i;
        API.graphql({
          query: updateDocLineItemSortOrder,
          variables: {
            doc_line_item_id: item.doc_line_item_id,
            sort_order: i
          }
        });
      });
    }
    
    consoleLog("handleOnDragEndLineItem itemsCategory", itemsCategory);
    
    setLineItems([...itemsCategory]);
    let allData = { ...stData };
    consoleLog("see catttt", structureIndex, itemsCategory);
    allData.structures[structureIndex].categories.find(
      (c) => c.category_id == props.category.category_id
    ).line_items = [...itemsCategory];
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    if(typeof props.url_params.doc_id != 'undefined'){
      props.updateDocOnElasticSearch();
    }
  }

  async function loadDefaultLineItems(){
    consoleLog('loadDefaultLineItems', props.category.category_id);
    // consoleLog('loadDefaultLineItems', default_category);

    let d_data = default_category.find((c) => c.category_id == props.category.category_id).default_data;
    if(d_data != null){
      props.setShowLoaderx(true);
      d_data = JSON.parse(d_data);
      consoleLog('loadDefaultLineItems d_data', d_data[1].line_items);

      let new_line_ids = d_data[1].line_items;
      new_line_ids = new_line_ids.join();
      consoleLog('loadDefaultLineItems new_line_ids', new_line_ids);

      const get_line_items_data = await API.graphql({
        query: getLineItemByIDs,
        variables: {
          ids: new_line_ids,
        },
      });

      let new_line_items = JSON.parse(get_line_items_data.data.getLineItemByIDs);
      new_line_items = JSON.parse(new_line_items).data;
      consoleLog('loadDefaultLineItems new_line_items>>>',new_line_items);

      let current_line_items = props.category.line_items;

      consoleLog('loadDefaultLineItems current_line_items', current_line_items);

      let results = [];
      results = new_line_items.filter(({ line_item_id: id1 }) => !current_line_items.some(({ line_item_id: id2 }) => id2 === id1));

      consoleLog('loadDefaultLineItems results', results);

      if(results.length > 0){
        for(let i=0; i<results.length; i++){
          current_line_items.push(results[i]);
        }
        let allData = { ...stData };
        allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = new_line_items;
      
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        setStData(allData);
      }
      consoleLog('loadDefaultLineItems >>>>>>>>>>', current_line_items);
      props.setShowLoaderx(false);
    }
  }

  const defaultManufacturerx = [...defaultManufacturers];
  defaultManufacturerx.unshift({value: 'Select', label: 'No Manufacturer'});
  return (
    <Cat>
      <Card.Body className="w-100">

        {options?.show_categories === 1 && (
          <div
            className="addcatagory"
            id="cutom_bg_line"
            onClick={() => toggleShowAddCat(!showAddCat)}
          >

            <div className="accordian-icon">
              <img src={showAddCat ? ExpandUp : ExpandDown} style={{ width: "23px", height: "auto" }} alt="" />
            </div>
            <EdiText
              onSave={updateCategoryTitle}
              viewContainerClassName="my-custom-view-wrapper"
              editButtonContent={
                <React.Fragment>
                  <div className="d-flex align-center-center">
                    <p
                      className="mb-0"
                      style={{
                        fontFamily: "Rajdhani",
                        marginLeft: "-5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#D6E1E5",
                      }}
                    >
                      EDIT
                    </p>
                  </div>
                </React.Fragment>
              }
              saveButtonContent={
                <p
                  className="mb-0"
                  style={{
                    fontFamily: "Rajdhani",
                    marginLeft: "-5px",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#D6E1E5",
                    marginTop: "5px",
                  }}
                >
                  DONE
                </p>
              }
              cancelButtonContent={<FaTrashAlt size={16} color={"#D6E1E5"} />}
              onCancel={removeCategory}
              hideIcons={true}
              type="text"
              value={props.category.name}
              renderValue={(value) => {
                return (
                  <span>
                    {props.displayCategoryPrice ? props.category.name + " - $" + categoryTotal : props.category.name}
                  </span>
                );
              }}
            />
          </div>
        )}  
        {showAddCat && (
          <div className="catBox">
            <Row
              className="p-2 pl-5 hide-xs m-0 border-bottom"
              style={{ background: "#D6E1E5", minHeight: "41px" }}
            >
              <Col
                className="customCol col-xl-6 col-md-3 col"
                style={{ paddingLeft: "10px" }}
              >
                ITEM NAME
              </Col>
              <Col className="customCol qty col-1 col-md-2 col-xl-1 col">
                QUANTITY
              </Col>
              <Col className="customCol unit col-1 col-md-2 col-xl-1">UNIT</Col>
              <Col
                className="customCol col-1 col-md-2 col-xl-1"
                style={{ paddingLeft: "10px" }}
              >
                UNIT PRICE
              </Col>
              <Col className="customCol net-cost col-1">NET COST</Col>
              {/* <Col className="customCol col-1 text-center">
                            NOTE 
                        </Col>
                        <Col className="customCol col-1 text-center">
                            EDIT 
                        </Col> */}
            </Row>
            <Row className="d-flex flex-column m-0 w-child-100">
              <DragDropContext onDragEnd={handleOnDragEndLineItem}>
                <Droppable droppableId="droppable-1">
                  {(provided, _) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {lineItems.map((line_item, i) => (
                        <Draggable
                          key={line_item.line_item_id}
                          draggableId={"draggable-" + line_item.line_item_id}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? "0 0 .7rem #000"
                                  : "none",
                                background: i % 2 == 1 ? "#E3E3E3" : "",
                              }}
                            >
                              <Handle {...provided.dragHandleProps}>
                                <img
                                  src={Move}
                                  alt=""
                                  style={{ paddingLeft: "13px" }}
                                />
                              </Handle>
                              <LineItem
                                structureIndex={structureIndex}
                                structureId={props.category.doc_structure_id}
                                categoryId={props.category.category_id}
                                line_item={line_item}
                                onChange={handleLineItemChange}
                                setSubTotal={props.setSubTotal}
                                calculateSubTotal={props.calculateSubTotal}
                                calculateStructureTotal={props.calculateStructureTotal}
                                calculateCategoryTotal={calculateCategoryTotal}
                                url_params={props.url_params}
                                show={props.show}
                                showMessage={props.showMessage}
                                showMessageType={props.showMessageType}
                                setShow={props.setShow}
                                setShowMessage={props.setShowMessage}
                                setShowMessageType={props.setShowMessageType}
                                updateDocOnElasticSearch={props.updateDocOnElasticSearch}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>

            {inputList.map((x, i) => {
              return (
                <div className="box">
                  <input
                    name="note"
                    placeholder="Enter Notes"
                    className="ip my-2"
                    value={x.note}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  <div className="btn-box">
                    <button
                      className="mr10"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}

            {!inputList.length == 0 && (
              <button className="mr10" onClick={() => handleAddClick()}>
                Add Another Line
              </button>
            )}

            <div
              className="addItems container-spacing"
              style={{ background: "#D6E1E5", minHeight: "41px", borderRadius: "0 0 6px 6px" }}
            >
              <div className="pt-3 pb-3">
                <button className="custombtnn pl-4" onClick={ShowLineItemRender}>
                  <p
                    className="m-0 paragraph-styling d-flex align-items-center"
                    style={{
                      fontFamily: "Rajdhani",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <span className="mr-3 h-auto">
                      {" "}
                      <img
                        src={CLight}
                        style={{ width: "25px", height: "auto" }}
                        alt=""
                      />
                    </span>{" "}
                    <b>ADD A LINE</b>
                  </p>
                </button>
              </div>
              {showLineItem && (
                <div
                  className="lineItemBox w-100 pt-4 pl-5 pr-5 pb-2"
                  style={{ background: "#EEF2F4" }}
                >
                  <div className="w-100 py-3 d-flex align-items-center justify-content-between">
                    <h5
                      className="m-0"
                      style={{
                        fontSize: "18px",
                        fontFamily: "Roboto",
                        color: "#212E33",
                      }}
                    >
                      <strong>Add a Line</strong>
                    </h5>
                    <img
                      src={Cancel}
                      style={{ width: "26px", height: "auto", cursor: "pointer" }}
                      onClick={ShowLineItemRender}
                      alt=""
                    />
                  </div>

                  <div className="my-5">
                    <p
                      className="text"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "#212E33",
                        fontWeight: "500",
                        marginBottom: "7px",
                      }}
                    >
                      Filter by manufacturer:
                    </p>
                    <Select
                      options={defaultManufacturerx} // set list of the data
                      onChange={handleManufecturerChange}
                      components={{ DropdownIndicator }}
                      placeholder="No Manufacturer"
                      className="custom-input-y border-none"
                    />
                  </div>

                  <div className="my-5">
                    <p
                      className="text"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "#212E33",
                        fontWeight: "500",
                        marginBottom: "7px",
                      }}
                    >
                      Start by typing the name of a line item you‘d like to add here
                    </p>
                    <div className="d-flex align-items-center">
                      <input
                        onChange={handleLineItemChangeText}
                        className="w-100 custom-input-x"
                        placeholder="Enter Line Item Name"
                        value={lineItemSearchText}
                      />
                      <a
                        className="add-btn"
                        onClick={() => handleAddItemAdd()}
                      > {lineItemSearchLoader ?
                          <FaCircleNotch size={22} className="awesome_spinner" style={{color:'#414141'}}/>
                        :
                          <img src={Plus} alt="" />
                        }
                      </a>
                    </div>
                    {lineItemAdd &&
                      <div className="lineItem-selection">
                        <Select
                          options={reportTypes} // set list of the data
                          onChange={handleChangeScopeType}
                          components={{ DropdownIndicator }}
                          placeholder="Select a Report Type"
                          className="custom-input-y border-none line-item-add-first line-spacing"
                        />
                        <Select
                          options={reportUnits} // set list of the data
                          onChange={handleChangeScopeCalc}
                          components={{ DropdownIndicator }}
                          placeholder="Select a Calculation to Use"
                          className="custom-input-y border-none line-item-add-first line-spacing"
                        />
                        <button className="custombtnn" onClick={() => handleCreateLineItem()}>
                          <p
                            className="text"
                            style={{
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              color: "rgb(247, 148, 28)",
                              fontWeight: "500",
                              marginBottom: "0px",
                            }}
                          >
                            { Object.keys(selectedScopeCalc).length === 0 ? 'Skip' : 'Add'}
                          </p>
                        </button>
                      </div>
                    }
                  </div>
                  <div className="line-item-listing">
                    {manufecturerLineItemToggle && (
                      <>
                      <Row className="search-title m-0">
                        <h5 className="col-12 ">
                          Select an existing line item or add a custom line item
                          by clicking the plus icon.
                        </h5>
                      </Row>
                      <div className="item-list line-item-item-list">
                        {manufacturersLineItems
                          .filter((manu_li) =>
                            manu_li.name
                              .toLowerCase()
                              .includes(lineItemSearchText.toLocaleLowerCase())
                          )
                          .map((manufecturer_item, i) => (
                            <div
                              className="item item-value line-item-add-item search-result"
                              key={i}
                              onClick={() =>
                                handleLineItemSelect(
                                  manufecturer_item.line_item_id,
                                  manufecturer_item
                                )
                              }
                            >
                              <div className="checkbox item-label">
                                <div className="label-col col xs-11 left">
                                  <b className="fa fa-plus on-new"></b>
                                  <div className="label">
                                    {manufecturer_item.name}
                                  </div>
                                </div>
                                <div className="remove-col col xs-1 right"></div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Card.Body>
    </Cat>
  );
}

const Handle = styled.div`
  position: absolute;
  left: 0px;
  margin: 2.5rem 0px 0px 0.5em;
  z-index: 99;
  cursor: grab;
`;

const Cat = styled.div`
  min-height: 1px;
  width: 100%;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  justify-content: space-around;
  font-size: 12px;

  .line-item-listing{
    height:247px;
  }
  // .toast-body{
  //   font-size:14px !important;
  // }

  .lineItemBox {
    @media (max-width: 767px) {
      padding: 10px 15px !important;
      margin-top: 10px !important ;
    }
  }

  .lineItem-selection {
    display: flex;
    margin-top: 3rem;

    @media(max-width: 1024px){
        margin-top: 1rem;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .accordian-icon {
    position: absolute;
    right: 30px;
    top: 19px;
  }

  .unit {
    @media (min-width: 1024px) {
      padding-left: 25px;
    }
  }

  .qty {
    @media (min-width: 1024px) {
      padding-left: 10px;
    }
  }

  .net-cost {
    @media (min-width: 1024px) {
      padding-left: 10px;
    }
  }
  
  .line-item-add-first {
    margin-right: 10px;

    @media(max-width:1024px){
      width: 100% !important;
  }

    @media(max-width:767px){
        margin-right: 0;
    }
  }

  .custom-input-x {
    height: 44px;
    outline: none;
    padding: 10px;
    font-size: 14px;
    color: #212e33;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #b4bebf;
  }
  .custom-input-y {
    height: 44px;
    width:25%;
    outline: none;
    border-radius: 4px;

    @media(max-width:600px){
            width:100% !important;
        }
  }
  .add-btn {
    height: 44px;
    width: 44px;
    background: #d6e1e5;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;

    @media(max-width: 767px) {
      margin-right: 0 !important;
    }
  }
  .container-spacing {
    padding-top: 0;
  }
  .fDlEee {
    margin: 0.5em;
  }

  .border-bottom {
    border-bottom: 1px solid #c2c2c2;
    display: flex;
    align-items: center;
  }

  .card-body {
    padding: 0px;
    margin-bottom: 5px;
  }

  .label {
    color: #414141 !important;
    padding: 0.5em;
    cursor: pointer;
  }
  
  .css-83bb2w-menu {
    div:last-child {
       border-bottom: none;
    }
  }

  .item-label {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .item-list {
    background: white;
    border: 1px solid #414141;
    border-radius: 5px;
    overflow: hidden;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .paragraph-styling {
    line-height: normal;
  }
  .search-title {
    color: white;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0.5em;
    border: 1px solid #414141;
    border-bottom: 0;
  }

  .search-result {
    cursor: pointer;
    border-bottom: 1px solid #B4BEBF;
  }

  #cutom_bg_line {
    background-color: #737f82 !important;
    padding: 10px;
  }
  .addcatagory {
    cursor: pointer;
    color: white;
    position: relative;
    padding-left: 2em;
    padding-right: 56px !important;
  }

  .bg-color {
    background-color: lightgrey;
  }

  .line-item-item-list {
    max-height: 200px;
    overflow-y: scroll;
  }

  .selectLine {
    width: 100%;
    min-height: 20px;
    text-align: center;
    background: #6a6e6f;
    padding: 5px;
    margin: 10px 0;

    .text {
      width: 100%;
      padding: 5px;
      border-top: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
    }
  }
  .my-custom-view-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 20px;
    font-size: 18px;
    font-weight: 600;
    margin-left: -2px;
    font-style: normal;
    font-family: Roboto;
  }
  .styles-module_Editext__button__sxYQX {
    background-color: transparent !important;
    content: "Edit" !important;
    border: none !important;
    outline: none !important;
  }
  .styles-module_Editext__input__2-M50 {
    color: black !important;
  }
  .styles-module_Editext__edit_button__310_J {
    color: #c2c2c2 !important;
  }
  .styles-module_Editext__save_button__1Dlwo {
    color: #c2c2c2 !important;
  }
  .styles-module_Editext__cancel_button__26sqr {
    color: #c2c2c2 !important;
  }
  // .css-1uccc91-singleValue{
  //     top: 30% !important;
  // }

  .hide-xs {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .centre {
    @media(min-width: 1024px) {
      text-align: center !important;
    }

  }
  .customCol {
    // border: 1px solid #c2c2c2;
    text-align: left;
    padding: 0px;
    font-weight: 600;
    font-size: 12px;
    font-family: Rajdhani;
    text-decoration: initial;
  }
  .ip {
    width: 90% !important;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    /* border-color: #414141; */
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 24px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 2px 6px;
  }

  .custombtnn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left !important;

    @media(max-width: 767px){
      padding-inline: 6px !important;
    }
  }
  .search-title {
    font-size: 0.9em;
    font-weight: 600;
    color: white;
    background: #6a6e6f;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0.5em;
    border: 1px solid #414141;
    border-bottom: 0;
  }
`;

const NestedAccordian = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
