import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { Accordion, Card, Button, Table, Row, Col } from "react-bootstrap";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { FaRegWindowClose } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import CLight from "../../assets/add_gray.svg";
import config from "../../aws-exports";
import Trash from "../../assets/trash.svg";
import Plus from "../../assets/plus.svg";
import Cancel from "../../assets/cancel_line.svg";
import {FaCircleNotch} from "react-icons/fa";
// import ToastBox from '../Toast/Toast';
import { useSelector, useDispatch } from "react-redux";
import {
  removeCategory,
  saveCategory,
  getCategoryByUserID,
} from "../../graphql/queries";
import  secureLocalStorage  from  "react-secure-storage";

Amplify.configure(config);

export default function AddCatagory(props) {
  const {consoleLog} = require('../commonFunctions.js');
  consoleLog("AddCatagory props: ", props);

  const updatedStructure = useSelector((state) => state.templateStructure);
  const [structureIndex, setStructureIndex] = React.useState(
    props.structureIndex
  );
  const [addStructureTitle, setAddStructureTitle] = useState(true);
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [default_category, setDCategory] = React.useState(
    useSelector((state) => state.defaultCategories)
  );
  const [category_by_user_id, setDCategoryByUserId] = React.useState(
    useSelector((state) => state.getCategoryByUserID)
  );
  // const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setStData(updatedStructure);
    consoleLog("AddCatagory stData: ", stData);

    setStructureIndex(props.structureIndex);
    consoleLog("AddCatagory on useEffect sturctureIndex: ", structureIndex);
  }, [structureIndex]);

  async function fetchUserCategory() {
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;

    const userCategories = await API.graphql({
      query: getCategoryByUserID,
      variables: {
        user_id: client_id,
        team_id: team_id,
      },
    });
    setDCategoryByUserId(userCategories.data.getCategoryByUserID);
  }

  function showHideStructureForm() {
    consoleLog("showHideStructureForm", "()");
    addStructureTitle
      ? setAddStructureTitle(false)
      : setAddStructureTitle(true);
    consoleLog("addStructureTitle: ", addStructureTitle);
  }

  function addDefaultCategory(category) {
    // add user category to temp / doc
    consoleLog("addDefaultCategory", category);
    category.line_items = [];

    props.onChange(category, structureIndex);
  }

  function deleteCat(defaultCat) {
    //delete default category
    deleteCategory(defaultCat.category_id);
    var categoryCopy = [...category_by_user_id];

    consoleLog("categopry copy", categoryCopy);
    categoryCopy = categoryCopy.filter(
      (c) => c.category_id !== defaultCat.category_id
    );
    setDCategoryByUserId(categoryCopy);
  }

  async function deleteCategory(category_id) {
    const deleteTemp = await API.graphql({
      query: removeCategory,
      variables: {
        category_id: category_id,
      },
    });
  }

  async function addCategory(category) {
    // adding default/existing category to document/template
    consoleLog("addCategory category: ", category);
    consoleLog("addCategory structureIndex: ", structureIndex);
    let categoryCopy = JSON.parse(JSON.stringify(category));

    if (categoryCopy.e_line_items) {
      categoryCopy.line_items = [];
      delete categoryCopy.e_line_items;
      delete categoryCopy.w_line_items;
    }

    consoleLog("addCategory category>: ", categoryCopy);

    props.onAdd(categoryCopy, structureIndex);
  }

  const [categoryName, setCategoryName] = useState('');
  const [categoryNameLoader, setCategoryNameLoader] = useState(false);

  function saveTemplateCategory() {
    // add a default user category
    setCategoryNameLoader(true);
    const category = {};
    category.name = categoryName;
    consoleLog('saveTemplateCategory', categoryName);
    category.line_items = [];
    if (!categoryName || categoryName == "") {
      props.setShow(true);
      props.setShowMessage("Please enter Category name.");
      props.setShowMessageType("Error");
      return false;
    }
    insertUserCategory(categoryName);
  }

  async function insertUserCategory(name) {
    // add a default user category
    consoleLog("insertUserCategory-addcategory", name);

    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;

    const insert_user_category = await API.graphql({
      query: saveCategory,
      variables: {
        user_id: client_id,
        team_id: team_id,
        scope_type_id: 0,
        name: name,
        status: 1,
        default_data: "",
      },
    });
    consoleLog("insert_user_category: ", insert_user_category);
   
    setCategoryName('');
    setCategoryNameLoader(false);
    fetchUserCategory();
  }

  function changeCategoryName(e) {
    setCategoryName(e.target.value);
  }

  return (
    <AddStruct>
      <div className="row buttons structure-buttons">
        <a
          className={`add-structure show-item-adder text-left ${
            !addStructureTitle ? "borders" : ""
          }`}
          onClick={showHideStructureForm}
        >
          <p
            className="text m-0"
            style={{ fontSize: "16px", fontWeight: "500" }}
          >
            {" "}
            <span className="mr-3 h-auto">
              {" "}
              <img
                src={CLight}
                style={{ width: "25px", height: "auto" }}
                alt=""
              />
            </span>{" "}
            ADD A CATEGORY
          </p>
        </a>

        <Col
          xs={12}
          className={`section structure-adder item-adder col text-left ${
            addStructureTitle ? "hide" : ""
          }`}
        >
          <div className="w-100 d-flex align-items-center justify-content-between marginBottom">
            <h2>Add a Category</h2>
            <a onClick={showHideStructureForm}>
              {" "}
              <img
                src={Cancel}
                style={{ height: "auto", width: "26px", position:'absolute', top:'20px',right:'30px' }}
                alt=""
              />{" "}
            </a>
          </div>
          <div className="catBox">
            <p className="title">Select an existing Category to add</p>
            <div className="catBox_child">
                {default_category.filter((d_cat) => props.categories.every((cat) => cat.category_id != d_cat.category_id)).map((category, i) => ( // default categories
                  <button
                    key={i}
                    onClick={() => addCategory(category)}
                    className="w-100 custombtnn text-dark bg-white text-left "
                  >
                    {category.name}
                  </button>
                ))}
              {category_by_user_id &&
                category_by_user_id.filter((d_cat) => props.categories.every((cat) => cat.category_id != d_cat.category_id)).map((defaultCat,i) => (
                    <div className="d-flex align-items-center">
                      <button
                        key={i}
                        className="w-100 text-dark custombtnn  bg-white text-left "
                      >
                        <span style={{width:"97%", height:"100%"}} onClick={() => addDefaultCategory(defaultCat)}>{defaultCat.name}</span>

                      <div className="trash-button">
                        <FaTrashAlt
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteCat(defaultCat)}
                          color={"gray"}
                          size={15}
                        />
                      </div>
                      </button>
                    </div>
                  )
                )}
            </div>
            <div className="create-form form">
              <div>
                <p className="title marginY">Create a new Category</p>
              </div>
              <input type="hidden" name="type" value="markup" />
              <div className="input d-flex">
                <Col className="col">
                  <input
                    onInput={(e) => changeCategoryName(e)}
                    type="text"
                    name="name"
                    className="custom-input-x"
                    placeholder="New Category Name"
                    defaultValue=""
                    data-item-adder-focus="true"
                    value={categoryName}
                  />
                </Col>
                <Col className="col text-right plus-width">
                  <button
                    className="search-submit small"
                    onClick={() => saveTemplateCategory()}
                  >
                    {categoryNameLoader
                      ?
                        <FaCircleNotch size={22} className="awesome_spinner" style={{color:"#343a40"}}/>
                      :
                        <img src={Plus} alt="" />
                    }
                  </button>
                </Col>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </AddStruct>
  );
}

const AddStruct = styled.div`
  min-height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 10px;
  
  .close {
    right: 20px !important;
    top: 1rem !important;
  }

  .toast-header {
    padding: 0 20px;
  }
  .toast-body {
    padding: 0 20px;
  }

  .structure-buttons {
    margin-top: 10px;
  }

  .trash-button {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .search-submit {
    margin-left: 0 !important;
  }

  .plus-width {
    max-width: 40px;
    height: 40px;
    margin-left: 0.71em;
  }

  .marginY {
    margin-top: 40px;
  }
  .catBox {
    padding-right: 1.35em;
  }
  .catBox_child {
    width: 100%;
    outline: none;

    .custombtnn {
      display: flex;
      justify-content: space-between;
      outline: none;
      border : none;
      font-weight: normal;
      border-bottom: 1px solid #b4bebf;
      border-right: 1px solid #b4bebf;
      border-left: 1px solid #b4bebf;
      font-size: 14px;
      line-height: 18px;
      font-family: Roboto;
      padding: 0.8em 0.71em;

      &:hover  {
        color: #F7941C !important; 
      }
    }
    }

    .custombtnn:first-child {
      border-top: 1px solid #b4bebf;
      border-radius : 5px 5px 0 0;
    }
    .custombtnn:last-child {
      border-top: none;
      border-radius : 0 0 5px 5px;
    }
    button {
      border-bottom: 1px solid #c2c2c2;
    }
  }
  .buttons {
    padding: 0;
  }

  .title {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
  }

  .row {
    position: relative;
    display: block;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    float: none;
  }
  .left {
    float: left;
  }
  .add-structure {
    background: #737f82;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    padding: 0.6em 1.8em 0.6em 1.2em;
    font-size: smaller;
  }
  .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.borders {
      border-radius : 6px 6px 0 0 !important;
    }
  }
  a {
    color: #414141;
    text-decoration: none;
    outline: 0;
  }
  .color-white {
    color: white;
  }
  .add-structure .text {
    font-size: 16px;
    font-family: Rajdhani;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .buttons .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    justify-content: flex-start;
  }
  .item-adder.hide {
    min-height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;
    display: none;
  }
  .item-adder {
    overflow: hidden;
    -webkit-animation: height-show 0.5s forwards;
    animation: height-show 0.5s forwards;
    background: #eef2f4;
    padding: 2em 2.07em 3.57em 3.43em !important;
    position: relative;
    border: 1 px solid #eef2f4;
    border-radius: 0 0 10px 10px;

    @media (max-width: 600px) {
      padding: 2em 1em !important;
    }
  }
  .marginBottom {
    margin-bottom: 2.35em;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }
  .item-adder .close {
    position: absolute;
    top: 1em;
    right: 2em;
    cursor: pointer;
    z-index: 5;
  }
  .item-adder h2 {
    font-size: 1.1em;
    font-weight: 600;
    margin: 0;
  }
  .col {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    position: relative;
    min-height: 1px;
    line-height: 1em;
    width: 100%;
    float: none;
    margin-right: -0.3em !important;
  }
  .col:last-child {
    margin-right: 0 !important;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
  h2 {
    font: 1.5em "Open Sans", Helvetica, sans-serif;
  }
  div,
  span,
  form {
    border: 0;
    font-size: 100%;
    font: inherit;
    line-height: 1.4em;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
  }
  .item-adder .create-form {
    margin-top: 1em;
    padding: 0;
  }
  .custom-input-x {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    padding: 0.71em 0.64em 0.86em;
    border: 1px solid #b4bebf;
    outline: none;
    font-size: 14px;
    line-height: 18px;
    color: #212e33;
    font-style: italic;
  }
  .button,
  .row .button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: #ed1c24;
    border: 1px solid #08527d;
    border-radius: 3px;
    padding: 0.8em 0.1em;
  }

  button.small,
  .button.small,
  .row .button.small {
    height: 40px;
    width: 40px;
    background: #d6e1e5;
    border-radius: 3px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e1e5;
    margin-left: 10px;
  }
  .item-adder .create-form button {
    padding: 0.7em 1em;
  }
`
