export default {
  "RoofScope": {
    "total_squares": {
      "name": "Total Squares",
      "algo": "total_squares",
      "quantity": 0,
      "unit": "SQ"
    },
    "standard_slope_area": {
      "name": "Standard Slope Area",
      "algo": "standard_slope_area",
      "quantity": 0,
      "unit": "SQ"
    },
    "low_slope_area": {
      "name": "Low Slope Area",
      "algo": "low_slope_area",
      "quantity": 0,
      "unit": "SQ"
    },
    "steep_slope_area": {
      "name": "Steep Slope Area",
      "algo": "steep_slope_area",
      "quantity": 0,
      "unit": "SQ"
    },
    "high_roof_area": {
      "name": "High Roof Area",
      "algo": "high_roof_area",
      "quantity": 0,
      "unit": "SQ"
    },
    "flat_roof_area": {
      "name": "Flat Roof Area",
      "algo": "flat_roof_area",
      "quantity": 0,
      "unit": "SQ"
    },
    "slope_area": {
      "name": "Sloped Roof",
      "algo": "slope_area",
      "quantity": 0,
      "unit": "SQ"
    },
    "eave": {
      "name": "Eave Length",
      "algo": "eave",
      "quantity": 0,
      "unit": "LF"
    },
    "rake": {
      "name": "Rake Length",
      "algo": "rake",
      "quantity": 0,
      "unit": "LF"
    },
    "flat_drip_edge": {
      "name": "Flat Drip Edge Length",
      "algo": "flat_drip_edge",
      "quantity": 0,
      "unit": "LF"
    },
    "parapet": {
      "name": "Parapet Length",
      "algo": "parapet",
      "quantity": 0,
      "unit": "LF"
    },
    "coping": {
      "name": "Coping Length",
      "algo": "coping",
      "quantity": 0,
      "unit": "LF"
    },
    "headwall_flashing": {
      "name": "Headwall Flashing Length",
      "algo": "headwall_flashing",
      "quantity": 0,
      "unit": "LF"
    },
    "hip": {
      "name": "Hip Length",
      "algo": "hip",
      "quantity": 0,
      "unit": "LF"
    },
    "ridge": {
      "name": "Ridge Length",
      "algo": "ridge",
      "quantity": 0,
      "unit": "LF"
    },
    "hip_and_ridge": {
      "name": "Hip and Ridge",
      "algo": "hip_and_ridge",
      "quantity": 0,
      "unit": "LF"
    },
    "clear_story": {
      "name": "Clear Story Length",
      "algo": "clear_story",
      "quantity": 0,
      "unit": "LF"
    },
    "valley": {
      "name": "Valley Length",
      "algo": "valley",
      "quantity": 0,
      "unit": "LF"
    },
    "slope_change": {
      "name": "Slope Change Length",
      "algo": "slope_change",
      "quantity": 0,
      "unit": "LF"
    },
    "step_flashing": {
      "name": "Step Flashing Length",
      "algo": "step_flashing",
      "quantity": 0,
      "unit": "LF"
    },
    "total_perimeter": {
      "name": "Total Perimeter Length",
      "algo": "total_perimeter",
      "quantity": 0,
      "unit": "LF"
    },
    "starter_strip": {
      "name": "Starter Strip Length",
      "algo": "starter_strip",
      "quantity": 0,
      "unit": "LF"
    },
    "ice_water_shield": {
      "name": "Ice and Water Shield (Eaves) Area",
      "algo": "ice_water_shield",
      "quantity": 0,
      "unit": "SF"
    },
    "ice_water_shield_valleys": {
      "name": "Ice and Water Shield (Valleys) Area",
      "algo": "ice_water_shield_valleys",
      "quantity": 0,
      "unit": "SF"
    },
    "ice_water_shield_eaves_valleys": {
      "name": "Ice and Water Shield (Eaves and Valleys) Area",
      "algo": "ice_water_shield_eaves_valleys",
      "quantity": 0,
      "unit": "SF"
    },
    "felt_15": {
      "name": "Felt Underlayment (15 LB)",
      "algo": "felt_15",
      "quantity": 0,
      "unit": "SQ"
    },
    "felt_30": {
      "name": "Felt Underlayment (30 LB)",
      "algo": "felt_30",
      "quantity": 0,
      "unit": "SQ"
    },
    "felt_90": {
      "name": "Felt Underlayment (90 LB)",
      "algo": "felt_90",
      "quantity": 0,
      "unit": "SQ"
    }
  },
  "GutterScope": {
    "gutter_length": {
      "name": "Gutter Length",
      "algo": "gutter_length",
      "quantity": 0,
      "unit": "LF"
    },
    "end_caps": {
      "name": "End Caps",
      "algo": "end_caps",
      "quantity": 0,
      "unit": "EA"
    },
    "gutter_inside_corner": {
      "name": "Inside Corners",
      "algo": "gutter_inside_corner",
      "quantity": 0,
      "unit": "EA"
    },
    "gutter_outside_corner": {
      "name": "Outside Corners",
      "algo": "gutter_outside_corner",
      "quantity": 0,
      "unit": "EA"
    },
    "gutter_corners": {
      "name": "All Corners",
      "algo": "gutter_corners",
      "quantity": 0,
      "unit": "EA"
    },
    "gutter_and_downspout": {
      "name": "Gutter and Downspout Length",
      "algo": "gutter_and_downspout",
      "quantity": 0,
      "unit": "LF"
    },
    "downspout": {
      "name": "Downspout Length",
      "algo": "downspout",
      "quantity": 0,
      "unit": "LF"
    }
  },
  "SidingScope": {
    "total_siding": {
      "name": "Total Siding Area",
      "algo": "total_siding",
      "quantity": 0,
      "unit": "SF"
    },
    "starter_strip": {
      "name": "Starter Strip Length",
      "algo": "starter_strip",
      "quantity": 0,
      "unit": "LF"
    },
    "fascia_soffit": {
      "name": "Fascia/Soffit Length",
      "algo": "fascia_soffit",
      "quantity": 0,
      "unit": "LF"
    },
    "frieze_board": {
      "name": "Frieze Board Length",
      "algo": "frieze_board",
      "quantity": 0,
      "unit": "LF"
    },
    "door_count": {
      "name": "Door Count",
      "algo": "door_count",
      "quantity": 0,
      "unit": "EA"
    },
    "door_wrap": {
      "name": "Door Wrap Length",
      "algo": "door_wrap",
      "quantity": 0,
      "unit": "LF"
    },
    "garage_door_count": {
      "name": "Garage Door Count",
      "algo": "garage_door_count",
      "quantity": 0,
      "unit": "EA"
    },
    "garage_wrap": {
      "name": "Garage Wrap Length",
      "algo": "garage_wrap",
      "quantity": 0,
      "unit": "LF"
    },
    "window_count": {
      "name": "Window Count",
      "algo": "window_count",
      "quantity": 0,
      "unit": "EA"
    },
    "window_wrap": {
      "name": "Window Wrap Length",
      "algo": "window_wrap",
      "quantity": 0,
      "unit": "LF"
    },
    "siding_inside_corner": {
      "name": "Inside Corner Length",
      "algo": "siding_inside_corner",
      "quantity": 0,
      "unit": "LF"
    },
    "siding_outside_corner": {
      "name": "Outside Corner Length",
      "algo": "siding_outside_corner",
      "quantity": 0,
      "unit": "LF"
    },
    "siding_misc_trim": {
      "name": "Misc Trim Length",
      "algo": "siding_misc_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "horizontal_lap": {
      "name": "Horizontal Lap Siding Area",
      "algo": "horizontal_lap",
      "quantity": 0,
      "unit": "SF"
    },
    "vertical_lap": {
      "name": "Vertical Lap Siding Area",
      "algo": "vertical_lap",
      "quantity": 0,
      "unit": "SF"
    },
    "shingle": {
      "name": "Shingle Siding Area",
      "algo": "shingle",
      "quantity": 0,
      "unit": "SF"
    },
    "brick": {
      "name": "Brick Area",
      "algo": "brick",
      "quantity": 0,
      "unit": "SF"
    },
    "concrete": {
      "name": "Concrete Area",
      "algo": "concrete",
      "quantity": 0,
      "unit": "SF"
    },
    "stone": {
      "name": "Stone Area",
      "algo": "stone",
      "quantity": 0,
      "unit": "SF"
    },
    "stucco": {
      "name": "Stucco Area",
      "algo": "stucco",
      "quantity": 0,
      "unit": "SF"
    },
    "cmu": {
      "name": "CMU Area",
      "algo": "cmu",
      "quantity": 0,
      "unit": "SF"
    },
    "panel": {
      "name": "Panel Siding Area",
      "algo": "panel",
      "quantity": 0,
      "unit": "SF"
    },
    "timber": {
      "name": "Timber Siding Area",
      "algo": "timber",
      "quantity": 0,
      "unit": "SF"
    },
    "metal": {
      "name": "Metal Siding Area",
      "algo": "metal",
      "quantity": 0,
      "unit": "SF"
    },
    "outdoor_ceiling": {
      "name": "Outdoor Ceiling Area",
      "algo": "outdoor_ceiling",
      "quantity": 0,
      "unit": "SF"
    }
  },
  "InsulationScope": {
    "total_attic": {
      "name": "Total Attic Area",
      "algo": "total_attic",
      "quantity": 0,
      "unit": "SF"
    },
    "attic_perimeter": {
      "name": "Attic Perimeter Length",
      "algo": "attic_perimeter",
      "quantity": 0,
      "unit": "LF"
    }
  },
  "ConcreteScope": {
    "concrete_perimeter": {
      "name": "Perimeter",
      "algo": "concrete_perimeter",
      "quantity": 0,
      "unit": "LF"
    },
    "surface_area": {
      "name": "Surface Area",
      "algo": "surface_area",
      "quantity": 0,
      "unit": "SF"
    },
    "volume_depth_2": {
      "name": "Volume at 2in Depth",
      "algo": "volume_depth_2",
      "quantity": 0,
      "unit": "CF"
    },
    "volume_depth_4": {
      "name": "Volume at 4in Depth",
      "algo": "volume_depth_4",
      "quantity": 0,
      "unit": "CF"
    },
    "volume_depth_6": {
      "name": "Volume at 6in Depth",
      "algo": "volume_depth_6",
      "quantity": 0,
      "unit": "CF"
    }    
  },
  "PaintScope": {
    "total_paint": {
      "name": "Total Paint Area",
      "algo": "total_paint",
      "quantity": 0,
      "unit": "SF"
    },
    "soffit": {
      "name": "Soffit Area",
      "algo": "soffit",
      "quantity": 0,
      "unit": "SF"
    },
    "outdoor_ceiling": {
      "name": "Outdoor Ceiling Area",
      "algo": "outdoor_ceiling",
      "quantity": 0,
      "unit": "SF"
    },
    "total_trim": {
      "name": "Total Trim Length",
      "algo": "total_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "fascia_trim": {
      "name": "Fascia Trim Length",
      "algo": "fascia_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "corner_trim": {
      "name": "Corner Trim Length",
      "algo": "corner_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "railing": {
      "name": "Railing Length",
      "algo": "railing",
      "quantity": 0,
      "unit": "LF"
    },
    "door_trim": {
      "name": "Door Trim Length",
      "algo": "door_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "shutters": {
      "name": "Number of Shutters",
      "algo": "shutters",
      "quantity": 0,
      "unit": "EA"
    },
    "garage_trim": {
      "name": "Garage Trim Length",
      "algo": "garage_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "window_trim": {
      "name": "Window Trim Length",
      "algo": "window_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "paint_misc_trim": {
      "name": "Misc Trim Length",
      "algo": "paint_misc_trim",
      "quantity": 0,
      "unit": "LF"
    },
    "paint_1": {
      "name": "Paint 1 Area",
      "algo": "paint_1",
      "quantity": 0,
      "unit": "SF"
    },
    "paint_2": {
      "name": "Paint 2 Area",
      "algo": "paint_2",
      "quantity": 0,
      "unit": "SF"
    },
    "paint_3": {
      "name": "Paint 3 Area",
      "algo": "paint_3",
      "quantity": 0,
      "unit": "SF"
    },
    "paint_4": {
      "name": "Paint 4 Area",
      "algo": "paint_4",
      "quantity": 0,
      "unit": "SF"
    }
  }
}