import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { Accordion, Card, Button, Table, Row, Col } from "react-bootstrap";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { FaRegWindowClose } from "react-icons/fa";
import { FaPlus, FaInfoCircle, FaCircleNotch } from "react-icons/fa";
import { FaTrashAlt, FaPercent } from "react-icons/fa";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import Trashh from "../../assets/trash.svg";
import TransparentTrash from "../../assets/trash_transparent.svg";
import Plus from "../../assets/plus.svg";
import Cancel from "../../assets/cancel_line.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import  secureLocalStorage  from  "react-secure-storage";

import {
  removeCategory,
  saveCategory,
  getCategoryByUserID,
  saveDocMarkup,
  AddMarkupOrTax,
  deleteMarkupOrTax,
  deleteDocMarkup,
  updateMarkup,
  createOnRedis
} from "../../graphql/queries";
import RadioBoxRow from "./radioBoxRow";
Amplify.configure(config);

export default function AddTaxes(props) {
  const {consoleLog} = require('../commonFunctions.js');
  // consoleLog('AddTaxes props>>>',props.url_params);
  const [addStructureTitle, setAddStructureTitle] = useState(false);
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [default_manufecturer, setDManufecturer] = React.useState(
    useSelector((state) => state.defaultManufacturer)
  );
  const [default_category, setDCategory] = React.useState(
    useSelector((state) => state.defaultCategories)
  );

  const [addTaxLoader, setAddTaxLoader] = React.useState(0);
  
  var taxesArray = [];
  var taxesIdArray = [];

  var allTaxes = Object.entries(stData.markups);

  if (allTaxes.length > 0) {
   
    let i;

    let taxesElems = {};

    for (i = 0; i < allTaxes.length; i++) {
      if (allTaxes[i][1].type == "tax") {
        // taxesElems.id = allTaxes[i][1].markup_id;
        taxesElems.name = allTaxes[i][1].name;
        taxesElems.value = allTaxes[i][1].value;
        taxesElems.type = allTaxes[i][1].type;
        // taxesElems.sort_order = allTaxes[i][1].sort_order;
        taxesElems.percent = allTaxes[i][1].percent;
        taxesElems.for = allTaxes[i][1].for;
        taxesElems.markup_id = allTaxes[i][1].markup_id;
        taxesElems.default = allTaxes[i][1].default;
        // consoleLog('AddTaxes i: ', taxesElems);
        taxesArray.push(taxesElems);
        taxesIdArray.push(allTaxes[i][1].markup_id);
        taxesElems = {};
      }
    }
    consoleLog("AddTaxes array: ", taxesArray);
    consoleLog("AddTaxes array ids: ", taxesIdArray);
  }

  const [savedTax, addSavedTax] = React.useState(taxesArray);

  /////////////////////////// default / saved markups (Select Markup Fields to Add)

  var defTaxesArray = [];
  let defaultTaxes = props.default_tNm;
  if (props.default_tNm.length > 0) {
    consoleLog("fetchDefaultTaxesMarkups addTaxes>", props.default_tNm);

    let i;

    var taxeElems = {};
    for (i = 0; i < props.default_tNm.length; i++) {
      if (props.default_tNm[i].type == "tax") {
        if (!taxesIdArray.includes(props.default_tNm[i].markup_id)) {
          // taxeElems.id = props.default_tNm[i].markup_id;
          taxeElems.name = props.default_tNm[i].name;
          taxeElems.value = props.default_tNm[i].value;
          taxeElems.type = props.default_tNm[i].type;
          // taxeElems.sort_order = props.default_tNm[i].sort_order;
          taxeElems.percent = props.default_tNm[i].percent;
          taxeElems.for = props.default_tNm[i].for;
          taxeElems.markup_id = props.default_tNm[i].markup_id;
          taxeElems.default = props.default_tNm[i].default;
          // consoleLog('defTaxesArray i: ', taxeElems);
          defTaxesArray.push(taxeElems);
          taxeElems = {};
        }
      }
    }
    consoleLog("defTaxesArray", defTaxesArray); // load default / saved taxes
  }

  const [tax_by_user_id, setDTaxByUserId] = React.useState(defTaxesArray);

  //////////////////////////

  const dispatch = useDispatch();

  function handleValue(e, i) {
    consoleLog("handleValue e: ", e);
    consoleLog("handleValue i: ", i);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    e = 1 * e;

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.value = e;
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display taxes data on front end

    let allTaxes = Object.entries(allData.markups);

    let taxesArray = [];

    if (allTaxes.length > 0) {

      let i;

      let taxesElems = {};

      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "tax") {
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddTaxes i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedTax(taxesArray);
    }
  }

  function handlePercentage(e, i) {
    consoleLog("handlePercentage e: ", e);
    consoleLog("handlePercentage i: ", i);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    e = 1 * e;

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.percent = e;
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display taxes data on front end

    let allTaxes = Object.entries(allData.markups);

    let taxesArray = [];

    if (allTaxes.length > 0) {

      let i;

      let taxesElems = {};

      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "tax") {
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddTaxes i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedTax(taxesArray);
    }
  }

  function handleDefault(e, i) {
    consoleLog("handleDefault e: ", e);
    consoleLog("handleDefault i: ", i);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    let copy = e;

    let val = 0;
    if (copy == false || copy == "0" || copy == 0) {
      val = 1;
    }

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.default = val;
        // consoleLog('matched', item.markup_id);
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display taxes data on front end

    let allTaxes = Object.entries(allData.markups);

    var taxesArray = [];

    if (allTaxes.length > 0) {
     
      let i;

      let taxesElems = {};

      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "tax") {
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddTaxes i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedTax(taxesArray);
    }
  }

  function handleApplyToTotalCost(e, i, tax) {
    consoleLog("handleApplyToTotalCost e: ", e);
    consoleLog("handleApplyToTotalCost i: ", i);
    consoleLog("handleApplyToTotalCost tax: ", tax);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.for = e;
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display taxes data on front end

    let allTaxes = Object.entries(allData.markups);

    var taxesArray = [];

    if (allTaxes.length > 0) {

      let i;

      let taxesElems = {};

      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "tax") {
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddTaxes i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedTax(taxesArray);
    }
    updateMarkups(tax);
  }

  function handleApplyToMatCost(e, i) {
    consoleLog("handleApplyToMatCost e: ", e);
    consoleLog("handleApplyToMatCost i: ", i);
  }

  // function showHideTaxesForm(){
  //     addStructureTitle ? setAddStructureTitle(false) : setAddStructureTitle(true);
  //     consoleLog("addStructureTitle: " , addStructureTitle)
  // }

  async function addDefaultTax(tax) {
    // Add Default Tax to Template Taxes

    consoleLog("addDefaultTax tax> ", tax);
    setAddTaxLoader(tax.markup_id);
    
    var doc_idx = props.url_params.doc_id;
    if(typeof props.url_params.doc_id=='undefined'){
      doc_idx = 0;
    }

    try{
      const add_tax = await API.graphql({
        query: saveDocMarkup,
        variables: {
          doc_id: doc_idx,
          markup_id: tax.markup_id,
          type: tax.type,
          for: tax.for,
          name: tax.name,
          percent: tax.percent,
          sort_order: 0,
          value: tax.value,
        },
      });

      let taxxx = JSON.parse(add_tax.data.saveDocMarkup);

      consoleLog("addDefaultTax add_tax>", taxxx);

      let doc_id = taxxx.insertId;

      consoleLog('addDefaultTax savedTax>',savedTax);
      consoleLog('addDefaultTax typeof savedTax>', typeof savedTax);

      var taxCopy = [...savedTax];
      const taxData = {};
      taxData.markup_id = tax.markup_id;
      taxData.type = tax.type;
      taxData.value = tax.value;
      taxData.name = tax.name;
      taxData.sort_order = 0;
      taxData.percent = tax.percent;
      taxData.for = tax.for;
      taxData.default = tax.default;
      taxCopy.push(taxData);
      consoleLog('addDefaultTax taxCopy>',taxCopy);
      addSavedTax(taxCopy);
      let allData = stData;
      let tempObj = {};
      tempObj[doc_id] = taxData;
      
      consoleLog('addDefaultTax typeof allData.markups>',typeof allData.markups);
      consoleLog('addDefaultTax allData.markups>',allData.markups);
      consoleLog('addDefaultTax allData.markups Array.isArray>',Array.isArray(allData.markups));

      if(Array.isArray(allData.markups)){
        allData.markups = {};
      }

      // let newObj = { ...allData.markups, tempObj };
      let newObj = Object.assign(allData.markups, tempObj);
      allData.markups = newObj;

      // remove the object from default taxes
      var defTaxCopy = [...tax_by_user_id];
      defTaxCopy = defTaxCopy.filter((t) => t.markup_id !== tax.markup_id);
      setDTaxByUserId(defTaxCopy);

      setStData(allData);

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      consoleLog('addDefaultTax allData>', JSON.stringify(allData));

      setAddTaxLoader(0);

      if(typeof props.url_params.doc_id != 'undefined'){
        props.updateDocOnElasticSearch();
      }

      props.calculateSubTotal(allData);

      consoleLog("addDefaultTax newObj>", newObj);
      consoleLog("addDefaultTax allData.markups>", allData.markups);
    }catch(err){
      console.error(err);
    }
  }

  async function deleteTempTax(tax) {
    // delete template tax
    var taxCopy = [...savedTax]; // template taxes
    var defTaxCopy = [...tax_by_user_id]; // default taxes [to add back this tax to default taxes]
    consoleLog("deleteTempTax>", tax);
    consoleLog("deleteTempTax taxCopy>", taxCopy);
    consoleLog("deleteTempTax defTaxCopy>", defTaxCopy);

    taxCopy = taxCopy.filter((t) => t.markup_id !== tax.markup_id);
    addSavedTax(taxCopy); //tax removed on the front end
    consoleLog("deleteTempTax taxCopy updated>", taxCopy);

    let taxObj = {};
    taxObj.name = tax.name;
    taxObj.value = tax.value;
    taxObj.type = tax.type;
    taxObj.percent = tax.percent;
    taxObj.for = tax.for;
    taxObj.markup_id = tax.markup_id;
    defTaxCopy.push(taxObj);
    consoleLog("deleteTempTax>>", defTaxCopy);
    setDTaxByUserId(defTaxCopy); // tax added to default taxes

    let allData = {...stData};
    consoleLog("deleteTempTax allData.markups>>>", allData.markups);

    let ind = Object.entries(allData.markups).find(m=>(m[1].markup_id==tax.markup_id))[0];

    consoleLog("deleteTempTax tax.markup_id>>>", tax.markup_id);
    consoleLog("deleteTempTax ind>>>", ind);

    delete allData.markups[ind];

    consoleLog("deleteTempTax allData>>>", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    try{
      const del_tax = await API.graphql({
        query: deleteDocMarkup,
        variables: {
          doc_markup_id: ind,
        },
      });
      consoleLog("deleteTempTax del_tax>>>", del_tax);
    }catch(err){
      console.error(err);
    }

    if(typeof props.url_params.doc_id != 'undefined'){
      props.updateDocOnElasticSearch();
    }
  }

  async function deleteDefTax(tax) {
    // delete default tax
    let defaultTaxes = props.default_tNm;
    consoleLog('deleteDefTax defaultTaxes:', defaultTaxes);
    consoleLog('deleteDefTax markup_id:', tax.markup_id);
    defaultTaxes = defaultTaxes.filter((t) => t.markup_id !== tax.markup_id);
    props.set_default_tNm(defaultTaxes);
    consoleLog('deleteDefTax defaultTaxes>:', defaultTaxes);

    var taxCopy = [...tax_by_user_id];
    consoleLog('deleteDefTax', tax);
    taxCopy = taxCopy.filter((t) => t.markup_id !== tax.markup_id);
    setDTaxByUserId(taxCopy);
    consoleLog('deleteDefTax', taxCopy);
    let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    try{
      const del_def_tax = await API.graphql({
        query: deleteMarkupOrTax,
        variables: {
          markup_id: tax.markup_id,
        },
      });
      consoleLog('deleteDefTax>>>', del_def_tax);

      const create_tm_redis = await API.graphql({
        query: createOnRedis,
        variables: {
          id: userId,
          index: 'prodocs_taxes_and_markups',
          data: JSON.stringify(defaultTaxes)
        }
      });
      consoleLog('deleteDefTax create_tm_redis:', create_tm_redis);
    }catch(err){
      consoleLog('deleteDefTax',err);
    }
  }

  async function addTax(category) {
    consoleLog("addTax category: ", category);
    category.line_items = [];
    props.onAdd(category);
  }

  const [taxName, setTaxName] = useState('');
  const [creatingTax, setCreatingTax] = useState(false);

  let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
  let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;

  async function saveTemplateTax() {
    // Add a Default Tax
    consoleLog('saveTemplateTax', taxName);

    if(!taxName || taxName==''){
      props.setShow(true);
      props.setShowMessage('Please enter Tax name.');
      props.setShowMessageType('Error');
      return false;
    }

    setCreatingTax(true);

    try{
      const taxObj = {
          user_id: client_id,
          team_id: team_id,
          type: "tax",
          name: taxName,
          value: 10,
          for: "all",
          percent: 50.0,
          default: 1,
          status: 1,
        };

      const add_def_tax = await API.graphql({
        query: AddMarkupOrTax,
        variables: taxObj,
      });

      consoleLog("add_def_tax>", add_def_tax.data.AddMarkupOrTax);

      let taxxx = JSON.parse(add_def_tax.data.AddMarkupOrTax);

      consoleLog("add_def_tax>", taxxx.insertId);

      let tax_id = taxxx.insertId;

      var taxCopy = [...tax_by_user_id];
      const tax = taxObj;
      tax.markup_id = tax_id;
      taxCopy.push(tax);
      defaultTaxes.push(tax);

      setTaxName('');

      await API.graphql({
        query: createOnRedis,
        variables: {
          id: client_id,
          index: 'prodocs_taxes_and_markups',
          data: JSON.stringify(defaultTaxes)
        }
      });

      setDTaxByUserId(taxCopy);
      consoleLog("object", taxCopy);
      props.setShow(true);
      props.setShowMessage('Tax has been added!');
      props.setShowMessageType('Success');
      setCreatingTax(false);
    }catch(err){
      console.error(err);
      setCreatingTax(false);
    }
  }

  function changeTaxName(e) {
    // taxName = e.target.value;
    setTaxName(e.target.value);
  }

  const [checked, setChecked] = useState(false);
  const [checked_sec, setChecked_sec] = useState(false);
  const handleClick = () => setChecked(!checked);
  const handleClick_sec = () => setChecked_sec(!checked_sec);

  async function updateMarkups(markup){
    if(typeof props.url_params.doc_id != 'undefined'){
      consoleLog('updateMarkups', markup);

      let allData = stData;
      let allDataEntries = Object.entries(allData.markups);
      consoleLog("updateMarkups allDataEntries>>>", allDataEntries);

      let i;
      let ind;
      for (i = 0; i < allDataEntries.length; i++) {
        if (allDataEntries[i][1].markup_id == markup.markup_id) {
          ind = allDataEntries[i][0];
        }
      }
      consoleLog("updateMarkups ind>>>", ind);
      
      try{
        const update_markup = await API.graphql({
          query: updateMarkup,
          variables: {
            doc_markup_id: ind,
            type: markup.type,
            value: markup.value,
            for: markup.for,
            percent: markup.percent
          },
        });
        consoleLog("updateMarkups update_markup", update_markup);
        props.updateDocOnElasticSearch();
      }catch(err){
        console.error(err);
      }
    }
  }

  // useEffect(() => {
  //     props.getData(totalMatCostPercentage, totalCostPercentage);
  // }, []);

  return (
    <AddStruct>
      <Row className="mb-2">
        <div className="col-xs-9 text-left d-flex align-items-center justify-content-between w-100">
          <h4 className="tax_text">
            Add Tax{" "}
              <Tooltip
                  overlay={
                    <div
                    style={{
                      width: "250px",
                      padding: "10px",
                      background: "#fff",
                      color: "#000",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="d-flex flex-column justify-content-center"
                    >
                      <b>Taxes</b><br />
                      Add tax fields as needed. Apply a tax only to the material costs or to a % of total costs on your estimate. To save the value of a tax field for future use, check “Save Default”.
                    </div>
                  }
                  placement="left"
                  >
                  <small
                    style={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                    <FaInfoCircle
                    size={15}
                    style={{ color: "#F7941C" }}
                    />
                  </small>
              </Tooltip>
          </h4>
          <button
            className="border-0 px-0 outline-none bg-trans"
            onClick={() => 
              props.hideMarkupOrTaxes('taxes')
            }
          >
            <img
              src={Cancel}
              style={{ height: "auto", cursor: "pointer", width: "26px" }}
              alt=""
            />
          </button>
        </div>
      </Row>

      {savedTax.map((taxD, i) => (
        <div className="d-flex  justify-content-center w-100 flex-column">
          <p className="m-0 mt-2">{taxD.name} (%)</p>

          <div className="tax-input d-flex align-items-center justify-content-center w-100">
            <input
              name={"tax-" + i}
              placeholder="Enter Percentage"
              className="custom-link-input custom-link-1 my-2 w-100 "
              value={taxD.value}
              onChange={(e) => handleValue(e.target.value, taxD.markup_id)}
              onBlur={()=>updateMarkups(taxD)}
            />

            <div className="save-input w-25 d-flex align-items-center justify-content-start ml-5 test-center">
              <input
              className="mt-0"
                type="checkbox"
                checked={taxD.default}
                onClick={() => handleDefault(taxD.default, taxD.markup_id)}
              />
              <small
                style={{
                  color: "#737F82",
                  marginTop: "2px",
                  marginLeft: "5%",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0",
                  lineHeight: "16px",
                }}
              >
                Save as default
              </small>
            </div>
            <div className="ex-tax mr-4">
              <img
                src={TransparentTrash}
                style={{ cursor: "pointer" }}
                onClick={() => deleteTempTax(taxD)}
                alt=""
              />
            </div>
          </div>
          <Row className="markup-section d-flex align-items-center justify-content-between w-75">
            <div className="markup-section-first w-50 d-flex align-items-center justify-content-start test-center my-2">
              <input
                type="radio"
                className="mt-0"
                onClick={() =>
                  handleApplyToTotalCost("material", taxD.markup_id, taxD)
                }
                checked={taxD.for == "material" ? true : false}
              />
              <small
                style={{
                  color: "#737F82",
                  marginTop: "3px",
                  marginLeft: "5%",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0",
                  lineHeight: "16px",
                }}
              >
                Apply to Material Cost only
              </small>
            </div>

            <div className="markup-section-second w-50 d-flex align-items-center justify-content-start test-center my-2">
              <input
                type="radio"
                className="mt-0"
                onClick={() => handleApplyToTotalCost("all", taxD.markup_id, taxD)}
                checked={taxD.for == "all" ? true : false}
              />
              <small
                className="d-flex align-items-center justify-content-center"
                style={{
                  color: "#737F82",
                  marginTop: "3px",
                  marginLeft: "5%",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0",
                  lineHeight: "16px",
                }}
              >
                Apply to{" "}
                <span>
                  {" "}
                  <input
                    type="text"
                    onChange={(e) =>
                      handlePercentage(e.target.value, taxD.markup_id)
                    }
                    onBlur={()=>updateMarkups(taxD)}
                    value={taxD.percent}
                    style={{
                      width: "50px",
                      height: "40px",
                      outline: "none",
                      border: "none",
                      margin: "0 5px",
                    }}
                    placeholder="0"
                  />{" "}
                </span>
                % of Total Cost{" "}
              </small>
            </div>
          </Row>
        </div>
      ))}

      <div className="row buttons pb-5">
        <a
          className={`show-item-adder text-left ${
            !addStructureTitle ? "hide" : ""
          }`}
        >
          <span className="mr-2">
            <FaPlus color={"#F7941C"} size={15} />
          </span>
          <span className="text">Add additional Tax Field</span>
        </a>

        <Col
          xs={12}
          className={`section structure-adder item-adder col p-0 text-left ${
            addStructureTitle ? "hide" : ""
          }`}
        >
          <div className="catBox">
            <p
              className="mt-4"
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "#212E33",
                fontWeight: "500",
                textAlign: "left",
                width: "100%",
                marginBottom: '2px'
              }}
            >
              Select Tax Fields to Add
            </p>
            <div className="catBox_child bg-white">
              {
                (Array.isArray(tax_by_user_id) && tax_by_user_id.length)
                  ? (tax_by_user_id.map((tax, i) => (
                    <div className="d-flex align-items-center hover-opt">
                      <button
                        key={i}
                        className="w-100 text-dark custombtnn   bg-white text-left "
                      >
                        <a
                          href="javascript:;"
                          className="hover-opt"
                          onClick={() => addDefaultTax(tax)}
                        >
                          {tax.name}
                        </a>
                        { addTaxLoader == tax.markup_id ?
                          <span className="float-right">
                            <FaCircleNotch
                              size={18}
                              className="awesome_spinner"
                              style={{color:'#212e33'}}
                            />
                          </span>
                          :
                          <span className="float-right">
                            <img
                              src={Trashh}
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteDefTax(tax)}
                              alt=""
                            />
                          </span>
                        }
                      </button>
                    </div>
                  )))
                  : <span style={{marginLeft:"5px", paddingBottom: "2px"}}>You don't have default Taxes. Add a custom Tax name and click on the + button to add.</span>
              }
            </div>
          </div>
          <div className="create-form form">
            <input type="hidden" name="type" value="markup" />
            <p
              className="ml-2 mt-5"
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "#212E33",
                fontWeight: "500",
                marginBottom: '2px'
              }}
            >
              Add a Custom Tax Field
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <input
                onInput={(e) => changeTaxName(e)}
                type="text"
                name="name"
                className="custom-link-input"
                placeholder="Add Custom Tax Name"
                data-item-adder-focus="true"
                value={taxName}
              />

              <button
                className="search-submit ml-3 small"
                onClick={() => saveTemplateTax()}
              >
                {creatingTax ? 
                  <FaCircleNotch
                      size={18}
                      className="awesome_spinner"
                      style={{color:'#212e33'}}
                  />
                :
                  <img src={Plus} alt="" />
                }
              </button>
            </div>
          </div>
        </Col>
      </div>
    </AddStruct>
  );
}

const AddStruct = styled.div`
  min-height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px 45px;

  border-radius: 6px;
  background-color: #eef2f4;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }
  .close{
        right:20px !important;
        top:0px !important;
        background:transparent !important;
        outline:none;
    }

    .toast-header{
        padding:0 20px;
    }
    .toast-body{
        padding:0 20px;
    }
  .hover-opt {
    text-decoration: none;
    color: #212e33;
    &:hover {
      color: #f7941c !important;
    }
  }

  .search-submit {
    height: 40px !important;
    width: 40px !important;
  }
  .ex-tax {
    height: 40px;
    width: 50px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background: #d6e1e5;
    border: none;
    outline: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    @media(max-width: 767px) {
      margin-right: 0 !important;
      width: 40px;
    }
  }

  .width {
    width: 98.5%
  }
  .tax-input {
    @media(max-width: 1024px){
     justify-content: space-between !important;
    }
  }

  .markup-section {
    @media(max-width: 767px){
      width: 100% !important;
      flex-direction: column;
      justify-content: unset !important;
      margin-top: 10px !important;
      f
     }
  }

  .markup-section-first{
    @media(max-width: 767px){
    width: 100% !important;
    }
  }

  .markup-section-second{
    @media(max-width: 767px){
    width: 100% !important;
    }
  }
  

  .custom-link-1 {

    @media(max-width: 1024px){
      width: 60% !important;
     }

    @media(max-width: 767px){
      width: 40% !important;
     }
  }

  .save-input {
    @media(max-width: 767px){
      margin-left: 0 !important;
      width: auto !important;

      small {
        margin-left: 5px !important;
        font-size: 13px !important;
      }
     }
  }
  .custom-link-input {
    height: 40px;
    width: 100%;
    border: 1px solid #b4bebf;
    outline: none;
    border-radius: 4px;
    padding: 10px;
    color: #212e33;
    font-style: normal;
    padding: 10px;
  }

  .custombtnn {
    font-weight: 500 !important;
    font-size: 14px;
    text-decoration: initial;
  }

  .w-15 {
    width: 15%;
  }
  .w-25 {
    width: 25%;
  }
  .top {
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: 600;
    padding: 0.5em;
    vertical-align: top;
  }
  .tax_text {
    // text-transform: uppercase;

    color: #212e33;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .popup-tooltip {
    font-size: 0.8em;
    text-transform: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2em;
  }

  .save-default {
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
    vertical-align: top;
  }
  .custom_span {
    color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -25px;
    z-index: 9;
    border-radius: 5px;
  }
  .sec_row {
    input[type="checkbox"] {
      border: 1px solid #000;
      border-radius: 50%;
    }
  }

  .catBox_child {
    width: 98.5%;
    border: 1px solid #b4bebf;
    border-radius: 4px;
    padding: 5px 0px 0px;
    outline: none;
    button {
      border-bottom: 1px solid #c2c2c2;
    }
  }
  .buttons {
    padding: 0;
  }

  .row {
    position: relative;
    display: block;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    float: none;
  }
  .left {
    float: left;
  }
  .add-structure {
    background: #414141;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    padding: 0.6em 1.8em 0.6em 1.2em;
  }
  .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  a {
    color: #414141;
    text-decoration: none;
    outline: 0;
  }
  .color-white {
    color: white;
  }
  .add-structure .text {
    color: white;
    font-size: 1em;
  }
  .buttons .show-item-adder .text {
    font-size: 1.2em;
    text-decoration: underline;
    padding-left: 0.5em;
  }
  .buttons .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .item-adder.hide {
    min-height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;
    display: none;
  }
  .item-adder {
    overflow: hidden;
    -webkit-animation: height-show 0.5s forwards;
    animation: height-show 0.5s forwards;
    
    position: relative;
  }
  .item-adder .close {
    position: absolute;
    top: 1em;
    right: 2em;
    cursor: pointer;
    z-index: 5;
  }
  .item-adder h2 {
    font-size: 1.1em;
    text-transform: uppercase;
    margin: 0;
  }
  .col {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    position: relative;
    min-height: 1px;
    line-height: 1em;
    width: 100%;
    float: none;
    margin-right: -0.3em !important;
  }
  .col:last-child {
    margin-right: 0 !important;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
  h2 {
    font: 1.5em "Open Sans", Helvetica, sans-serif;
  }
  div,
  span,
  form {
    border: 0;
    font-size: 100%;
    font: inherit;
    line-height: 1.4em;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
  }
  .item-adder .create-form {
    margin-top: 1em;
  }
  button,
  .button,
  .row .button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: #f7941c;
    border: 1px solid #08527d;
    border-radius: 3px;
    padding: 0.8em 1em;
  }
  button,
  .button,
  .row .button {
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background: #d6e1e5;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0.6em 0.8em;
    box-sizing: border-box;
  }
  button.small,
  .button.small,
  .row .button.small {
    font-size: 0.8em;
    padding: 0.5em 1.2em;
  }
  .item-adder .create-form button {
    padding: 0.7em 1em;
  }
`;
